import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import EmailInfo from './EmailInfo'
import EmailPreview from './EmailPreview'
import CloseEmail from './CloseEmail'
import ViewToggle from './ViewToggle'
import Modal from '../../components/Modal/Modal'
import { RoundedButton } from '../../components/RoundedButton/RoundedButton'

import { ReactComponent as Logo } from '../../assets/icons/directions-to-port-logo-small.svg'
import MobileLogo from '../../assets/images/dtp-mobile-logo.png'

import './DirectionsToPortEmailContainer.scss'
import { useUI } from '../../context/modalContext'

const DirectionsToPortEmailContainer = () => {
  const [toggle, setToggle] = useState(false)
  const [email, setEmail] = useState({})
  const [error, setError] = useState(false)
  const [transition, setTransition] = useState('hidden')
  const [completed, setCompleted] = useState(true)
  const { navigateTo } = useUI()
  const { state } = useLocation()

  useEffect(() => {
    const mapsLink = state?.mapsLink || ''
    const origin = state?.origin || ''
    const destination = state?.destination || ''

    if (!mapsLink || !origin || !destination) navigateTo('/directions-to-port')
  }, [state])

  const toggleTransition = () => {
    if (!completed) return

    if (!toggle) {
      setTransition('offscreen')
      setCompleted(false)
      setTimeout(() => {
        setTransition('')
        setToggle(true)
        setCompleted(true)
      }, 1)
    } else {
      setTransition('offscreen')
      setToggle(false)
      setCompleted(false)
      setTimeout(() => {
        setTransition('hidden')
        setCompleted(true)
      }, 600)
    }
  }

  const onClose = () => {
    setError(false)
  }

  const handleOnError = () => {
    setError(true)
  }

  return (
    <>
      {error && (
        <Modal title="Oops, Email Not Sent" onClose={onClose}>
          <div className="dtp-modal-body-text">
            <p>
              We&apos;re sorry, but there was a problem sending your email.
              Unfortunately, it didn&apos;t go through as expected. Please try
              sending it again.
            </p>
          </div>
          <div className="dtp-center">
            <RoundedButton
              className="dtp-modal-btn"
              label="Close"
              onClick={onClose}
            />
          </div>
        </Modal>
      )}

      <div className="email-background">
        <div className="email-container">
          <div className="desktop-email-header">
            <Logo />
            <CloseEmail className="desktop-close-email" />
          </div>
          <div className="mobile-email-header">
            <img
              className="dtp-logo-mobile"
              src={MobileLogo}
              alt="Directions To Port Logo"
            />
            <CloseEmail className="mobile-close-email" />
          </div>
          <div className="email-preview-toggle">
            <ViewToggle
              className="email-preview-toggle-btn"
              onClick={() => toggleTransition()}
              status={toggle}
            />
          </div>
          <div className="email-content-wrapper">
            <div className="email-content">
              <EmailInfo
                className={`email-info-container ${toggle ? 'hidden' : ''}`}
                onChange={setEmail}
                onError={handleOnError}
              />
              <EmailPreview
                email={email}
                className={`email-preview-container ${transition}`}
                onClick={() => toggleTransition()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DirectionsToPortEmailContainer
