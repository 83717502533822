import { string } from 'prop-types'
import React from 'react'

const BoldedText = ({ text, shouldBeBold }) => {
  const textArray = text.split(RegExp(shouldBeBold, 'ig'))
  const match = text.match(RegExp(shouldBeBold, 'ig'))

  return (
    <span>
      {textArray.map((item, index) => (
        <span key={index}>
          {item}
          {index !== textArray.length - 1 && match && <b>{match[index]}</b>}
        </span>
      ))}
    </span>
  )
}

BoldedText.defaultProps = {
  text: '',
  shouldBeBold: '',
}

BoldedText.propTypes = {
  text: string,
  shouldBeBold: string,
}

export default BoldedText
