import { combineReducers } from 'redux'
import viewReducer from './view/viewReducer'
import configurationReducer from './configuration/configuration'
import brandsReducer from './brands/brandsReducer'
import shipsReducer from './ships/shipsReducer'
import regionsReducer from './regions/regionsReducer'
import sailingsReducer from './sailings/sailingsReducer'
import portsReducer from './ports/portsReducer'
import filtersReducer from './filters/filtersReducer'
import announcementsReducer from './announcements/announcementsReducer'
import itineraryUpdatesReducer from './itineraryUpdates/itineraryUpdatesReducer'
import authenticationReducer from './authentication/authentication'
import directionsToPortReducer from './directions-to-port/directionsToPortReducer'

const rootReducer = combineReducers({
  configurationData: configurationReducer,
  announcements: announcementsReducer,
  brands: brandsReducer,
  filters: filtersReducer,
  itineraryUpdates: itineraryUpdatesReducer,
  ports: portsReducer,
  regions: regionsReducer,
  sailings: sailingsReducer,
  ships: shipsReducer,
  view: viewReducer,
  authentication: authenticationReducer,
  directionsToPort: directionsToPortReducer,
})

export default rootReducer
