import React from 'react'
import propTypes from 'prop-types'

import './RoundedButton.scss'

export const RoundedButton = ({ label, icon, onClick, ...rest }) => {
  return (
    <button
      onClick={() => onClick()}
      {...rest}
      className={`rounded-button ${rest.className}`}
    >
      {label ? (
        <span className={`${icon ? 'button-label' : ''}`}>{label}</span>
      ) : null}
      {icon}
    </button>
  )
}

RoundedButton.defaultProps = {
  label: null,
  icon: null,
  onClick: () => {},
}

RoundedButton.propTypes = {
  label: propTypes.string,
  icon: propTypes.element,
  onClick: propTypes.func,
}
