import React from 'react'
import propTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { resetInitialState } from '../../../redux/directions-to-port/directionsToPortActions'
import { RoundedButton } from '../../../components/RoundedButton/RoundedButton'
import Modal from '../../../components/Modal/Modal'

import './EmailSent.scss'

const EmailSent = ({ destination, recipients, onClose }) => {
  const dispatch = useDispatch()

  const handleExit = () => {
    window.location.href = 'http://www.cruisingpower.com/'
  }

  return (
    <Modal title="Email Sent!" onClose={onClose}>
      <p>
        Directions to {destination} have been sent to the following recipients:
      </p>
      <p>{recipients.toString().replace(',', ', ')}</p>
      <div className="dtp-center">
        <RoundedButton
          className="dtp-modal-btn dtp-find-more"
          label="Find More Directions To Port"
          onClick={() => {
            window.utag.link({
              event_name: 'click',
              page_url: window.location.href,
              category: 'navigation',
              interaction_type: 'Directions to Port',
              nav_link_text: 'Find More Directions To Port',
            })
            dispatch(resetInitialState())
            onClose()
          }}
        />
      </div>
      <div className="dtp-center">
        <RoundedButton
          className="dtp-modal-btn dtp-modal-btn-transparent"
          label="Exit To Cruising Power"
          onClick={() => {
            window.utag.link({
              event_name: 'click',
              page_url: window.location.href,
              category: 'navigation',
              interaction_type: 'Directions to Port',
              nav_link_text: 'Exit To Cruising Power',
            })
            handleExit()
          }}
        />
      </div>
    </Modal>
  )
}

EmailSent.defaultProps = {
  onClose: () => {},
}

EmailSent.propTypes = {
  destination: propTypes.string.isRequired,
  recipients: propTypes.arrayOf(propTypes.string).isRequired,
  onClose: propTypes.func,
}

export default EmailSent
