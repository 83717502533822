import React from 'react'
import { string, func, bool } from 'prop-types'

import './MapAndScheduleMenuItem.scss'

const MapAndScheduleMenuItem = ({
  name,
  onClickViewMap,
  onClickSchedule,
  hideViewOnMap,
  homePort,
  onClickHomePort,
}) => {
  return (
    <div className="component-ship-menu-item">
      <div className="title">{name}</div>
      <div className="links">
        {!hideViewOnMap && (
          <span className="basic-link" onClick={onClickViewMap}>
            View on Map
          </span>
        )}
        <span className="basic-link" onClick={onClickSchedule}>
          Schedule
        </span>
        {homePort && (
          <span className="basic-link" onClick={onClickHomePort}>
            Home Port
          </span>
        )}
      </div>
    </div>
  )
}

MapAndScheduleMenuItem.propTypes = {
  name: string.isRequired,
  onClickViewMap: func.isRequired,
  onClickSchedule: func.isRequired,
  hideViewOnMap: bool,
  homePort: string,
  onClickHomePort: func,
}

MapAndScheduleMenuItem.defaultProps = {
  hideViewOnMap: false,
  homePort: null,
  onClickHomePort: () => {},
}

export default MapAndScheduleMenuItem
