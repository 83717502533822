import React from 'react'
import './Filters.scss'
import { useMapContext } from '../MapProvider'

const Filters = () => {
  const { selectedFilter, setSelectedFilter } = useMapContext()

  return (
    <div className="filters-container">
      <div
        onClick={() => {
          window.utag.link({
            event_name: 'click',
            link_url: '/directions-to-port',
            page_url: window.location.href,
            category: 'map control',
            interaction_type: 'map control filter click',
            nav_link_text: 'Regions',
          })
          setSelectedFilter('regions')
        }}
        className={`filter-btn ${selectedFilter === 'regions' ? 'active' : ''}`}
      >
        Regions
      </div>
      <div
        onClick={() => {
          window.utag.link({
            event_name: 'click',
            link_url: '/directions-to-port',
            page_url: window.location.href,
            category: 'map control',
            interaction_type: 'map control filter click',
            nav_link_text: 'Home Ports',
          })
          setSelectedFilter('homeports')
        }}
        className={`filter-btn right ${
          selectedFilter === 'homeports' ? 'active' : ''
        }`}
      >
        Home Ports
      </div>
    </div>
  )
}

export default Filters
