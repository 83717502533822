import axios from 'axios'

const ENDPOINT_DOLLI = process.env.REACT_APP_ENDPOINT_DOLLI
const ENDPOINT_CONTENT = `${ENDPOINT_DOLLI}/rcl/content`
const ENDPOINT_AEM_PORTS = process.env.REACT_APP_DTP_CONTENT_ENDPOINT

const endpoints = {
  agent: `${ENDPOINT_DOLLI}/rcl/agent`,
  configuration: `${ENDPOINT_DOLLI}/config?`,
  brands: `${ENDPOINT_CONTENT}/brands`,
  ships: `${ENDPOINT_CONTENT}/ships`,
  regions: `${ENDPOINT_CONTENT}/destinations`,
  ports: `${ENDPOINT_CONTENT}/ports`,
  sailings: `${ENDPOINT_DOLLI}/rcl/vps-monthly-sailings`,
  announcements: `${ENDPOINT_CONTENT}/announcements`,
  itineraryUpdates: `${ENDPOINT_CONTENT}/itinerary-changes`,
  directionsToPort: `${ENDPOINT_DOLLI}/rcl/directions-to-port`,
  aemPorts: `${ENDPOINT_AEM_PORTS}/ports`,
}

export function configuration(query) {
  return axios.get(endpoints.configuration + query.toString())
}

export const getAgent = (sso) =>
  axios.get(`${endpoints.agent}/${sso}?renewData=${new Date().getTime()}`)

export function getBrands() {
  return axios.get(endpoints.brands)
}

export function getShips() {
  return axios.get(endpoints.ships)
}

export function getRegions() {
  return axios.get(endpoints.regions)
}

export function getSailings() {
  return axios.get(endpoints.sailings)
}

export function getPorts() {
  return axios.get(endpoints.ports)
}

export function getAnnouncements() {
  return axios.get(endpoints.announcements)
}

export function getItineraryUpdates() {
  return axios.get(endpoints.itineraryUpdates)
}

export function getPortsFromAEM() {
  const headers = {
    Accept: 'application/json',
    Apikey: process.env.REACT_APP_DOLLI_API_KEY,
  }

  return axios.get(endpoints.aemPorts, {
    headers,
  })
}

export function postDTPEmail(body) {
  return axios.post(endpoints.directionsToPort, JSON.stringify(body), {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}
