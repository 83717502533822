import React from 'react'
import './ExitButton.scss'
import { useDispatch } from 'react-redux'
import { resetInitialState } from '../../../redux/directions-to-port/directionsToPortActions'
import { useMapContext } from '../MapProvider'
import { useUI } from '../../../context/modalContext'

const ExitButton = () => {
  const { navigateTo } = useUI()
  const dispatch = useDispatch()
  const { directionsRenderer } = useMapContext()

  const handleExit = () => {
    window.utag.link({
      event_name: 'click',
      link_url: '/directions-to-port',
      page_url: window.location.href,
      category: 'map control',
      interaction_type: 'map control filter click',
      nav_link_text: 'Exit Directions to Port',
    })
    navigateTo('/')
    directionsRenderer.setMap(null)
    dispatch(resetInitialState())
  }

  return (
    <div className="btn-container">
      <div onClick={handleExit} className="btn">
        Exit Directions to Port
      </div>
    </div>
  )
}

export default ExitButton
