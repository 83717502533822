import React, { useState } from 'react'
import moment from 'moment'
import {
  number,
  shape,
  oneOf,
  func,
  arrayOf,
  string,
  bool,
  objectOf,
} from 'prop-types'
import { useSelector } from 'react-redux'
import {
  DATE_FORMAT,
  ENABLE_BOOKING,
  EQUOTE_URL,
} from '../../configuration/constants'
import { mapNewlinesAsBreaks } from '../../utilities/rendering/renderUtils'
import { RoundedButton } from '../RoundedButton/RoundedButton'
import { ReactComponent as DirectionsToPortIcon } from '../../assets/icons/directions-to-port.svg'

import BookOrQuotePackageCard from '../BookOrQuotePackageCard/BookOrQuotePackageCard'
import ShowMoreToggle from '../ShowMoreToggle/ShowMoreToggle'
import arrow from '../../assets/icons/blue-arrow.svg'
import { selectAgentSso } from '../../redux/authentication/authentication'
import selectPortPinData from '../../redux/selectors/selectPortPinData'
import { useUI } from '../../context/modalContext'

// TODO(ebarrett):
//  - Modify this to also apply for the Port and Destination
//     containers. They differ on only one column, so this should not require
//     styling changes.
//  - Perhaps a "renderMode" prop (or similar) to toggle accessor and headers
//  - Handlers will need to be passed in as well for buttons and filters
//  - Filter button state should not be managed internally, rather the button
//     state should be provided by whatever data comes in that dictates WHICH
//     filters are visible

const DeploymentTableRow = ({
  row,
  index,
  type,
  shownDeployments,
  showDeployment,
  icon,
  isShipVariant,
  content,
}) => {
  const { navigateTo } = useUI()
  const ssoParam = useSelector(selectAgentSso)
  const homeports = useSelector(selectPortPinData)
  const agentCountry = useSelector(
    (state) => state?.authentication?.agent?.country
  )
  const [bookPopout, setBookPopout] = useState(false)
  const [quotePopout, setQuotePopout] = useState(false)

  const isHomePort = homeports
    .map((port) => port.label)
    .includes(row.departurePort)

  const showCTA = agentCountry === 'USA' || agentCountry === 'CAN'

  const renderRowDates = (rowData) => {
    const datesFormatted = rowData.sailDates.map((d) =>
      moment(d).format(DATE_FORMAT)
    )
    return mapNewlinesAsBreaks(datesFormatted.join('\n'))
  }
  const renderRowPorts = (rowData) => {
    return rowData.ports.map((port, i) => (
      <span key={`id-${i}`}>
        <span>{port}</span>
        {i !== rowData.ports.length - 1 && (
          <img src={arrow} alt=" > " style={{ margin: '0 5px -2px 5px' }} />
        )}
      </span>
    ))
  }

  const openBookPopup = () => {
    setBookPopout(true)
    setQuotePopout(false)
  }

  const handleDeploymentQuoteClick = () => {
    const details = {
      event_name: 'deploy_quote_click',
      row_position: [],
      p_ship_code: [],
      p_brand: [],
      p_sail_date: [],
      p_itinerary_code: [],
      p_package_id: [],
      p_depart_port: [],
    }
    row.sailDates.forEach((date) => {
      details.row_position.push(index + 1)
      details.p_ship_code.push(row.shipCode)
      details.p_brand.push(row.brand)
      details.p_sail_date.push(moment(date).format('MM/DD/YYYY'))
      details.p_itinerary_code.push(row.shortCode.slice(2))
      details.p_package_id.push(row.shortCode)
      details.p_depart_port.push(row.departurePortCode)
    })
    window.utag.link(details)
  }

  const onDTPClick = () => {
    const details = {
      event_name: 'deploy_port_direction',
      row_position: [],
      p_ship_code: [],
      p_brand: [],
      p_sail_date: [],
      p_itinerary_code: [],
      p_package_id: [],
      p_depart_port: [],
    }

    row.sailDates.forEach((date) => {
      details.row_position.push(index + 1)
      details.p_ship_code.push(row.shipCode)
      details.p_brand = [row.brand]
      details.p_sail_date = [moment(date).format('MM/DD/YYYY')]
      details.p_itinerary_code.push(row.shortCode.slice(2))
      details.p_package_id = [row.shortCode]
      details.p_depart_port = [row.departurePortCode]
    })
    window.utag.link(details)

    navigateTo('/directions-to-port', {
      selectedPort: row.departurePort,
      type: 'regionOrShip',
      shipName: !isShipVariant ? content.name : null,
    })
  }

  const openQuotePopup = () => {
    setQuotePopout(true)
    setBookPopout(false)
  }

  const variantColumn = isShipVariant ? row.ship : row.region
  const variantLabel = isShipVariant ? 'Ship' : 'Region'

  const buildEQuoteUrl = (packageId) => (date) => {
    return (
      EQUOTE_URL +
      `?PackageCode=${packageId}&DepartureDate=${date}&sso=${ssoParam}&referrer=shipLocator`
    )
  }

  const desktopRow = (
    <tr key={`r_${index}`}>
      <td>{row.name}</td>
      <td>{renderRowDates(row)}</td>
      <td>{variantColumn}</td>
      <td>{row.departurePort}</td>
      <td>{renderRowPorts(row)}</td>
      <td className="actions">
        {showCTA && (
          <>
            {ENABLE_BOOKING && (
              <span className="action">
                <button className="book" onClick={openBookPopup}>
                  Book
                </button>
                <BookOrQuotePackageCard
                  title={row.name}
                  icon={icon}
                  dates={row.sailDates}
                  onContinue={(date) => date}
                  continueLabel="Continue to Booking"
                  isActive={bookPopout}
                  closeHandler={() => setBookPopout(false)}
                />
              </span>
            )}
            <span className="action">
              {isHomePort && (
                <RoundedButton
                  icon={<DirectionsToPortIcon />}
                  onClick={onDTPClick}
                />
              )}
              <button className="quote" onClick={openQuotePopup}>
                Quote
              </button>
              <BookOrQuotePackageCard
                title={row.name}
                icon={icon}
                dates={row.sailDates}
                continueLabel="Continue to Quote"
                isActive={quotePopout}
                closeHandler={() => setQuotePopout(false)}
                buildExternalUrl={buildEQuoteUrl(row.shortCode)}
                handleDeploymentQuoteClick={handleDeploymentQuoteClick}
              />
            </span>
          </>
        )}
      </td>
    </tr>
  )

  // serve desktop version first
  if (type === 'desktop') {
    return desktopRow
  }

  // then mobile version
  const mobleRow = (
    <div className="mobile-deployment-grid-wrapper" key={`r_${index}`}>
      <div
        className={`mobile-deployment-grid ${
          shownDeployments[index] ? '' : 'hidden'
        }`}
      >
        <div className="title">name</div>
        <div>{row.name}</div>
        <div className="title">Sail Date(s)</div>
        <div>{renderRowDates(row)}</div>
        <div className="title">{variantLabel}</div>
        <div>{variantColumn}</div>
        <div className="title">Port of Call</div>
        <div>{row.ports.join(' • ')}</div>

        <div className="actions">
          {ENABLE_BOOKING && (
            <span className="action">
              <button className="book" onClick={openBookPopup}>
                Book
              </button>
              <BookOrQuotePackageCard
                title={row.name}
                icon={icon}
                dates={row.sailDates}
                onContinue={(date) => date}
                continueLabel="Continue to Booking"
                isActive={bookPopout}
                closeHandler={() => setBookPopout(false)}
              />
            </span>
          )}
          <span className="action">
            {isHomePort && (
              <RoundedButton
                icon={<DirectionsToPortIcon />}
                onClick={onDTPClick}
              />
            )}
            <button className="quote" onClick={openQuotePopup}>
              Quote
            </button>
            <BookOrQuotePackageCard
              title={row.name}
              icon={icon}
              dates={row.sailDates}
              continueLabel="Continue to Quote"
              isActive={quotePopout}
              closeHandler={() => setQuotePopout(false)}
              buildExternalUrl={buildEQuoteUrl(row.shortCode)}
            />
          </span>
        </div>
      </div>

      <ShowMoreToggle
        show={!!shownDeployments[index]}
        onClickHandler={() => showDeployment(index)}
      />
    </div>
  )

  return mobleRow
}

DeploymentTableRow.defaultProps = {
  isShipVariant: false,
  content: {},
}

DeploymentTableRow.propTypes = {
  row: shape({}).isRequired,
  index: number.isRequired,
  type: oneOf(['desktop', 'mobile']).isRequired,
  shownDeployments: arrayOf(bool).isRequired,
  showDeployment: func.isRequired,
  icon: string.isRequired,
  isShipVariant: bool,
  content: objectOf(Object),
}

export default DeploymentTableRow
