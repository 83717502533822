import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './ShipContainer.scss'

import AlertNotice from '../../components/AlertNotice/AlertNotice'
import ShowMoreToggle from '../../components/ShowMoreToggle/ShowMoreToggle'
import ClickthroughDetails from '../../components/ClickthroughDetails/ClickthroughDetails'
import ClickthroughHeader from '../../components/ClickthroughHeader/ClickthroughHeader'
import DeploymentTable from '../../components/DeploymentTable/DeploymentTable'
import contentIcon from '../../assets/icons/rccl-content-tag-dark.svg'
import celebContentIcon from '../../assets/icons/celebrity-content-tag.svg'

import shipPin from '../../assets/icons/ship-pin.svg'
import shipPinCeleb from '../../assets/icons/celebrity-pin.svg'
import shipIcon from '../../assets/icons/menu-ships-icon.svg'

import selectShipViewContent from '../../redux/selectors/selectShipViewContent'
import selectShipViewDeployments, {
  selectAllDeploymentYears,
} from '../../redux/selectors/selectShipViewDeployments'
import selectShipViewDeploymentsFilterOptions from '../../redux/selectors/selectShipViewDeploymentsFilterOptions'
import { selectShipDeploymentsFilter } from '../../redux/filters/filtersSelectors'
import { setShipDeploymentsFilter } from '../../redux/filters/filtersActions'
import { selectViewOrigin } from '../../redux/view/viewSelectors'
import analyticsItineraryModalOpened from '../../utilities/analytics/analyticsItineraryModalOpened'

function generateDetailsComponentParam(content) {
  return {
    imageSrc: content?.imageSrc,
    sections: [
      {
        type: 'bio',
        label: 'Ship Bio',
        value: content?.bio,
      },
      {
        type: 'row',
        items: [
          {
            label: 'Sails to',
            value: content?.sailsTo.join(', '),
          },
          {
            label: 'Brand',
            value: content?.brand,
          },
          {
            label: 'Ship Class',
            value: content?.shipClass,
          },
        ],
      },
    ],
  }
}

const ShipContainer = () => {
  const dispatch = useDispatch()
  const fetchedDeployments = useRef(null)
  const [showShipInformation, setShowShipInformation] = useState(false)
  const content = useSelector(selectShipViewContent)
  const deployments = useSelector(selectShipViewDeployments)
  const deploymentYears = useSelector(selectAllDeploymentYears)
  const viewOrigin = useSelector(selectViewOrigin)

  const filter = useSelector(selectShipDeploymentsFilter)
  const filterOptions = useSelector(selectShipViewDeploymentsFilterOptions)
  const alertMessage = content?.alertMessage

  useEffect(() => {
    if (fetchedDeployments.current) {
      analyticsItineraryModalOpened({
        brand: content?.brand,
        ship: content?.name,
        sailsTo: content?.sailsTo,
        modalOriginationPoint: viewOrigin,
        region: deployments?.[0]?.region,
      })
    }
  }, [fetchedDeployments.current])

  useEffect(() => {
    if (
      deploymentYears?.length &&
      filter.year &&
      !deploymentYears.some((deploymentYear) => deploymentYear === filter.year)
    ) {
      dispatch(
        setShipDeploymentsFilter({
          year: deploymentYears[0],
        })
      )
    }
    fetchedDeployments.current = true
  }, [])

  const isCeleb = content?.brand === 'Celebrity'

  const clickthroughHeader = (
    <ClickthroughHeader
      icon={<img src={isCeleb ? celebContentIcon : contentIcon} alt="" />}
      pin={<img src={isCeleb ? shipPinCeleb : shipPin} alt="" />}
      title={content?.name}
    />
  )

  const filterChangeHandler = (updates) => {
    dispatch(setShipDeploymentsFilter(updates))
  }

  return (
    <div className="ship-container">
      <header className="mobile">
        <div className="title-row">
          <img src={shipIcon} alt="" />
          <div className="title">Ships</div>
        </div>
      </header>
      <header className="desktop">{clickthroughHeader}</header>
      <main>
        <div className="mobile">{clickthroughHeader}</div>
        {alertMessage && (
          <div className="alert-wrapper">
            <AlertNotice {...alertMessage} />
          </div>
        )}
        <div className="details-section">
          <div className="mobile show-additional-information">
            <div className="title">Ship Information</div>
            <ShowMoreToggle
              show={showShipInformation}
              onClickHandler={() =>
                setShowShipInformation(!showShipInformation)
              }
            />
          </div>
          <div
            className={`additional-information ${
              showShipInformation ? '' : 'hidden'
            }`}
          >
            <ClickthroughDetails {...generateDetailsComponentParam(content)} />
          </div>
        </div>

        <DeploymentTable
          deployments={deployments}
          years={deploymentYears}
          filter={filter}
          filterSections={filterOptions}
          updateFilters={filterChangeHandler}
          icon={shipIcon}
          content={content}
        />
      </main>
    </div>
  )
}

export default ShipContainer
