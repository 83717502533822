import React, { useState } from 'react'
import { AdvancedMarker } from '@vis.gl/react-google-maps/dist/index.umd.js' //eslint-disable-line
import { bool, func, objectOf, string } from 'prop-types'

import PortIcon from '../../../assets/icons/port-icon.svg'
import RegionPin from '../../../assets/icons/region-pin.svg'
import './Markers.scss'
import { useMapContext } from '../MapProvider'
import { RoundedButton } from '../../../components/RoundedButton/RoundedButton'
import { ReactComponent as DirectionsToPortIcon } from '../../../assets/icons/directions-to-port.svg'
import { useUI } from '../../../context/modalContext'

const Markers = ({ ports }) => {
  return ports.map((port) => <Marker key={port.shortCode} port={port} />)
}

const Label = ({ text, type }) => {
  return (
    <span
      className={`${type === 'region' ? 'region-pin-text' : 'port-pin-text'}`}
    >
      {text}
    </span>
  )
}

const PortInfoOrLabel = ({
  port,
  showInfo,
  onDestinationSelect,
  handleClickAnalyticsData,
}) => {
  return showInfo ? (
    <div className={`info-container ${showInfo ? 'show-info' : ''}`}>
      <div className="name-and-btn-container">
        <div className="port-name">{port.label}</div>
        <div id="port-address" className="port-address">
          {port.address}
        </div>
        <div className="port-btn">
          <RoundedButton
            className="get-directions"
            label="Get Directions"
            icon={<DirectionsToPortIcon />}
            onClick={() => {
              handleClickAnalyticsData(`${port.label}`)
              onDestinationSelect(port)
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <Label type="homeport" text={port.label} />
  )
}

const Marker = ({ port }) => {
  const [showInfo, setShowInfo] = useState(false)
  const { zoomIntoRegion, onDestinationSelect } = useMapContext()
  const { handleClickAnalyticsData } = useUI()

  const [zIndex, setZIndex] = useState(50)

  return (
    <AdvancedMarker
      zIndex={zIndex}
      position={{
        lat: port.lat,
        lng: port.lng,
      }}
      key={port.id}
      onClick={() => {
        if (port.type === 'region') {
          window.utag.link({
            event_name: 'click',
            page_url: window.location.href,
            category: 'regions',
            interaction_type: 'Directions to Port',
            nav_link_text: port.label, // set this as link text.
          })
          zoomIntoRegion(port)
        }
      }}
      onMouseEnter={() => {
        setShowInfo(true)
        setZIndex(999)
      }}
      onMouseLeave={() => {
        setShowInfo(false)
        setZIndex(50)
      }}
    >
      <div className="pin-container">
        <img
          className="pin-img"
          src={port.type === 'region' ? RegionPin : PortIcon}
          width={port.type === 'region' ? 30 : 24}
          alt="pin"
        />
        {port.type === 'region' ? (
          <Label key={port.id} type="region" text={port.label} />
        ) : (
          <PortInfoOrLabel
            key={port.id}
            port={port}
            showInfo={showInfo}
            onDestinationSelect={onDestinationSelect}
            handleClickAnalyticsData={handleClickAnalyticsData}
          />
        )}
      </div>
    </AdvancedMarker>
  )
}

Label.propTypes = {
  text: string.isRequired,
  type: string.isRequired,
}

PortInfoOrLabel.propTypes = {
  port: objectOf(Object).isRequired,
  showInfo: bool.isRequired,
  onDestinationSelect: func.isRequired,
  handleClickAnalyticsData: func.isRequired,
}

Marker.propTypes = {
  port: objectOf(Object).isRequired,
}

export default Markers
