import React, { useEffect, useState } from 'react'
import { arrayOf, shape, string, func, bool, objectOf } from 'prop-types'
import moment from 'moment'

import './DeploymentTable.scss'
import '../../containers/Modals/Modals.scss'

import DeploymentTableHead from './DeploymentTableHead'
import DeploymentTableRow from './DeploymentTableRow'
import Filters from '../Filters/Filters'
import arrowDown from '../../assets/icons/arrow-down.svg'

// TODO(ebarrett):
//  - Handlers will need to be passed in as well for buttons and filters
//  - Filter button state should not be managed internally, rather the button
//     state should be provided by whatever data comes in that dictates WHICH
//     filters are visible

const DeploymentTable = ({
  deployments,
  years,
  filter,
  filterSections,
  updateFilters,
  icon,
  isShipVariant,
  content,
}) => {
  const [shownDeployments, setShownDeployments] = useState(
    new Array(deployments.length).fill(false)
  )
  const [showFilters, setShowFilters] = useState(false)

  const showDeployment = (index) => {
    const temp = [...shownDeployments]
    temp[index] = !temp[index]
    setShownDeployments(temp)
  }

  useEffect(() => {
    const itineraryDetails = {
      event_name: 'ship_itinerary_result',
      row_position: [],
      p_ship_code: [],
      p_brand: [],
      p_sail_date: [],
      p_itinerary_code: [],
      p_package_id: [],
      p_depart_port: [],
      deploy_year: filter.year,
    }
    deployments.forEach((row, index) => {
      row.sailDates.forEach((sailDate) => {
        itineraryDetails.row_position.push((index + 1).toString())
        itineraryDetails.p_ship_code.push(row.shipCode)
        itineraryDetails.p_brand.push(row.brand)
        itineraryDetails.p_sail_date.push(moment(sailDate).format('MM/DD/YYYY'))
        itineraryDetails.p_itinerary_code.push(row.shortCode.slice(2))
        itineraryDetails.p_package_id.push(row.shortCode)
        itineraryDetails.p_depart_port.push(row.departurePortCode)
      })
    })
    window.utag.link(itineraryDetails)
  }, [])

  return (
    <>
      <div className="component-deployment-table">
        <div className="header">
          <div className="title">Itineraries for this Ship</div>
          <div className="filters">
            <div className="buttons">
              {years.map((year) => (
                <button
                  key={year}
                  className={filter.year === year ? 'active' : ''}
                  onClick={() => updateFilters({ year })}
                >
                  {year}
                </button>
              ))}
            </div>
            <div className="filters">
              <button
                className="filters-view"
                onClick={() => {
                  setShowFilters(true)
                }}
              >
                Refine
                <img src={arrowDown} alt="refine" className="down-arrow" />
              </button>
            </div>
          </div>
        </div>

        <div className="deployments">
          <table className="desktop">
            <DeploymentTableHead
              hasDeployments={!!deployments.length}
              isShipVariant={isShipVariant}
              nameTitle="Itinerary Name"
            />
            <tbody>
              {deployments.map((row, index) => (
                <DeploymentTableRow
                  key={index}
                  row={row}
                  index={index}
                  type="desktop"
                  shownDeployments={shownDeployments}
                  showDeployment={showDeployment}
                  icon={icon}
                  isShipVariant={isShipVariant}
                  content={content}
                />
              ))}
            </tbody>
          </table>
          <div className="mobile">
            <DeploymentTableHead
              hasDeployments={!!deployments.length}
              isShipVariant={isShipVariant}
              isMobile
            />
            {deployments.map((row, index) => (
              <DeploymentTableRow
                key={index}
                row={row}
                index={index}
                type="mobile"
                shownDeployments={shownDeployments}
                showDeployment={showDeployment}
                icon={icon}
                isShipVariant={isShipVariant}
                content={content}
              />
            ))}
          </div>
        </div>
      </div>
      {showFilters && (
        <Filters
          applyFilters={(updates) => {
            setShowFilters(false)
            updateFilters(updates)
          }}
          closePopout={() => setShowFilters(false)}
          filterSections={filterSections}
          filterValues={filter}
        />
      )}
    </>
  )
}

DeploymentTable.propTypes = {
  deployments: arrayOf(shape()),
  icon: string,
  years: arrayOf(string),
  filter: shape().isRequired,
  filterSections: arrayOf(shape()).isRequired,
  updateFilters: func.isRequired,
  isShipVariant: bool,
  content: {},
}

DeploymentTable.defaultProps = {
  deployments: [],
  icon: '',
  years: [],
  isShipVariant: false,
  content: objectOf(Object),
}

export default DeploymentTable
