import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactModal from 'react-modal'
import { APIProvider } from '@vis.gl/react-google-maps/dist/index.umd.js' // eslint-disable-line import/extensions
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min'
import {
  fetchConfiguration,
  selectConfigurationData,
} from '../../redux/configuration/configuration'
import { fetchBrands } from '../../redux/brands/brandsActions'
import { fetchShips } from '../../redux/ships/shipsActions'
import { fetchRegions } from '../../redux/regions/regionsActions'
import { fetchSailings } from '../../redux/sailings/sailingsActions'
import { fetchPorts } from '../../redux/ports/portsActions'
import { fetchAnnouncements } from '../../redux/announcements/announcementsActions'
import { fetchItineraryUpdates } from '../../redux/itineraryUpdates/itineraryUpdatesActions'

import { UIProvider } from '../../context/modalContext'
import Modals from '../Modals/Modals'
import MapContainer from '../Map/MapContainer'
import SideBar from '../SideBar/SideBar'
import DirectionsToPortContainer from '../DirectionsToPort/DirectionsToPortContainer'
import DirectionsToPortEmailContainer from '../DirectionsToPortEmail/DirectionsToPortEmailContainer'
import MapProvider from '../DirectionsToPort/MapProvider'

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#rcl__deployment-hub')
}

function App() {
  const configuration = useSelector(selectConfigurationData)
  const dispatch = useDispatch()

  useEffect(() => {
    // doing it this way rather then only on load to prevent having to wait when using hot reload.
    if (!configuration.data && !configuration.loading && !configuration.error) {
      dispatch(fetchConfiguration())
      dispatch(fetchBrands())
      dispatch(fetchShips())
      dispatch(fetchRegions())
      dispatch(fetchSailings())
      dispatch(fetchPorts())
      dispatch(fetchAnnouncements())
      dispatch(fetchItineraryUpdates())
    }
  }, [dispatch, configuration])

  return (
    <div className="rcl__deployment-hub" data-testid="rcl__deployment-hub">
      <link rel="stylesheet" href="https://use.typekit.net/ywr6egt.css" />
      <UIProvider>
        <Switch>
          <Route
            path={[
              '/directions-to-port/email',
              '/shiplocator/directions-to-port/email',
            ]}
          >
            <DirectionsToPortEmailContainer />
          </Route>
          <Route
            path={['/directions-to-port', '/shiplocator/directions-to-port']}
          >
            <APIProvider
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              libraries={['places']}
              version="3"
            >
              <MapProvider>
                <DirectionsToPortContainer />
              </MapProvider>
            </APIProvider>
          </Route>
          <Route path="/">
            <SideBar />
            <MapContainer />
            <Modals />
          </Route>
        </Switch>
      </UIProvider>
    </div>
  )
}

export default App
