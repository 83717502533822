import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../../../assets/icons/dtp-mobile-logo.svg'

import './MobileHeader.scss'

const MobileHeader = () => {
  return (
    <div id="mobile-header">
      <div className="logo-container">
        <Logo className="logo" />
        <div className="logo-text">Directions To Port</div>
      </div>
      <div className="exit-btn">
        <Link className="btn" to="/">
          Exit
        </Link>
      </div>
    </div>
  )
}

export default MobileHeader
