import React, { useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import './SideBar.scss'

import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import ItineraryAndAnnouncementsContainer from '../ItineraryAndAnnouncements/ItineraryAndAnnouncementsContainer'
import ArticleContainer from '../Article/ArticleContainer'
import DeploymentsContainer from '../Deployments/DeploymentsContainer'
import RegionContainer from '../Region/RegionContainer'
import RegionsContainer from '../Regions/RegionsContainer'
import ShipContainer from '../Ship/ShipContainer'
import ShipsContainer from '../Ships/ShipsContainer'
import PortContainer from '../Port/PortContainer'
import Drawer from '../../components/Drawer/Drawer'
import { useUI } from '../../context/modalContext'
import { useSSO } from '../../hooks/useSSO'
import { ReactComponent as Logo } from '../../assets/icons/ship-locator-logo-with-text.svg'
import { ReactComponent as MenuAnnouncementsIcon } from '../../assets/icons/menu-announcements-icon.svg'
import { ReactComponent as MenuAllDeploymentsIcon } from '../../assets/icons/menu-all-deployments-icon.svg'
import { ReactComponent as MenuShipsIcon } from '../../assets/icons/menu-ships-icon.svg'
import { ReactComponent as MenuRegionsIcon } from '../../assets/icons/menu-regions-icon.svg'
import { ReactComponent as MenuExitIcon } from '../../assets/icons/menu-exit-icon.svg'
import { ReactComponent as DirectionsToPortIcon } from '../../assets/icons/directions-to-port.svg'

import { ReactComponent as DownTriangle } from '../../assets/icons/down-triangle.svg'
import SvgIcon from '../../components/SvgIcon/SvgIcon'

import {
  selectMobileMenuIsOpen,
  selectSidebarView,
} from '../../redux/view/viewSelectors'
import {
  openSidebarView,
  closeSidebarView,
  openMobileMenu,
  closeMobileMenu,
} from '../../redux/view/viewActions'
import { setShipDeploymentsFilter } from '../../redux/filters/filtersActions'
import { getShipDefaultFilterOptions } from '../../redux/ships/shipUtils'
import triggerAnalyticsEvent from '../../utilities/analytics/triggerAnalyticsEvent'
/* changing to just "Announcements" temporarily as per Jira issue: RCL220-94
import { selectItineraryUpdatesReverseChronologicalArray } from '../../redux/itineraryUpdates/itineraryUpdatesSelectors'
 */

const SideBar = () => {
  const dispatch = useDispatch()
  const { displayModal, closeModal, handleClickAnalyticsData } = useUI()
  const sidebarView = useSelector(selectSidebarView)
  const mobileMenuIsOpen = useSelector(selectMobileMenuIsOpen)
  const [isClosing, setIsClosing] = useState(false)

  // URL Params for deep linking
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const [isDeepLinking, setIsDeepLinking] = useState(false)
  const ssoParam = useSSO()

  /* changing to just "Announcements" temporarily as per Jira issue: RCL220-94
  const newItineraryUpdateCount =
    useSelector(selectItineraryUpdatesReverseChronologicalArray)?.filter(
      (update) => update.isNew
    )?.length || 0
   */

  useEffect(() => {
    deepLink()
  }, [])

  useEffect(() => {
    if (displayModal && isDeepLinking) closeModal()
  }, [displayModal, isDeepLinking])

  const containerMap = {
    announcements: ItineraryAndAnnouncementsContainer,
    article: ArticleContainer,
    deployments: DeploymentsContainer,
    region: RegionContainer,
    regions: RegionsContainer,
    ship: ShipContainer,
    ships: ShipsContainer,
    port: PortContainer,
  }
  const VisibleContainer = containerMap[sidebarView]

  const analyticsEvents = {
    announcements: 'leftMenuAnnouncementsSelected',
    deployments: 'leftMenuDeploymmentsSelected',
    ships: 'leftMenuShipsSelected',
    regions: 'leftMenuRegionsSelected',
  }

  const swapView = (view, resourceKey = null) => {
    if (isClosing) {
      return
    }
    if (analyticsEvents[view]) {
      triggerAnalyticsEvent(analyticsEvents[view])
    }
    // ship filters are cleared in ShipContainer but we need to maintain this one until
    // the view changes
    dispatch(
      setShipDeploymentsFilter({
        ...getShipDefaultFilterOptions(),
      })
    )

    if (sidebarView === null) {
      dispatch(openSidebarView(view, resourceKey))
    } else {
      setIsClosing(true)
      setTimeout(() => {
        setIsClosing(false)
        if (view === sidebarView || view === null) {
          dispatch(closeSidebarView())
        } else {
          dispatch(openSidebarView(view, resourceKey))
        }
      }, 400)
    }
  }

  const handleMobileMenuToggle = () => {
    if (mobileMenuIsOpen) {
      dispatch(closeMobileMenu())
    } else {
      dispatch(openMobileMenu())
    }
  }

  const stripDeepLinking = () => {
    searchParams.delete('page')
    searchParams.delete('key')
    setIsDeepLinking(false)

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  /**
   * Deep Linking
   *
   * EX:
   * display port: page=port&key=YHZ
   * display ship: page=ship&key=AD
   * display destination (region): page=region&key=CARIB
   */
  const deepLink = () => {
    const supportedPages = ['port', 'ship', 'region']
    const page = searchParams.get('page')
    const key = searchParams.get('key')

    if (page && key && supportedPages.includes(page)) {
      setIsDeepLinking(true)
      dispatch(openSidebarView(page, key))
    }
  }

  const handleClose = () => {
    stripDeepLinking()
    swapView(null)
  }

  const handleNav = (view, resourceKey = null) => {
    handleClickAnalyticsData(view)
    stripDeepLinking()
    swapView(view, resourceKey)
  }

  return (
    <Fragment>
      <div id="side-bar" className={mobileMenuIsOpen ? 'open' : ''}>
        <header>
          <Logo className="logo" />
        </header>
        <main>
          <nav>
            <ul>
              <li>
                <div onClick={() => handleNav('announcements')}>
                  <div className="icon-with-count-container">
                    <MenuAnnouncementsIcon />
                    {/* changing to "Announcements" temporarily as per Jira issue: RCL220-94
                    {newItineraryUpdateCount > 0 ? (
                      <div className="icon-count">
                        {newItineraryUpdateCount}
                      </div>
                    ) : null}
                    */}
                  </div>
                  {/* changing to "Announcements" temporarily as per Jira issue: RCL220-94
                  Itinerary Updates
                  <br />& Announcements
                  */}
                  Announcements
                </div>
              </li>

              <li>
                <div onClick={() => handleNav('deployments')}>
                  <MenuAllDeploymentsIcon />
                  All Deployments
                </div>
              </li>

              <li>
                <div onClick={() => handleNav('ships')}>
                  <MenuShipsIcon />
                  Ships
                </div>
              </li>

              <li>
                <div onClick={() => handleNav('regions')}>
                  <MenuRegionsIcon />
                  Regions
                </div>
              </li>

              <li>
                <div>
                  <DirectionsToPortIcon />
                  <Link
                    onClick={() => handleClickAnalyticsData('directionsToPort')}
                    style={{ textDecoration: 'none', color: 'white' }}
                    to={`/directions-to-port${ssoParam}`}
                  >
                    Directions to Port
                  </Link>
                </div>
              </li>

              <li>
                <div
                  onClick={() => {
                    triggerAnalyticsEvent('leftMenuExitMapSelected')
                    window.location.href = 'http://www.cruisingpower.com/'
                  }}
                  className="exit"
                >
                  <MenuExitIcon />
                  Exit Map
                </div>
              </li>
            </ul>
          </nav>
        </main>
        <footer>
          <a
            href="https://secure.cruisingpower.com/protected/global/legal.do"
            target="_blank"
            rel="noreferrer"
          >
            Legal Information
          </a>
          <div className="copyright">
            &copy; {new Date().getFullYear()} Royal Caribbean International
          </div>
        </footer>
        <button
          type="button"
          className="menu-toggle"
          onClick={handleMobileMenuToggle}
        >
          {mobileMenuIsOpen ? 'Hide Menu' : 'View Menu'}
          {sidebarView ? (
            <SvgIcon
              type="close-x"
              className="toggle-state"
              fill="#3F6BAB" // medium-blue
              width="11"
              viewBox="0 0 16 16"
              height="11"
              style={{ top: '1px' }}
            />
          ) : (
            <DownTriangle />
          )}
        </button>
      </div>

      <Drawer
        isOpen={!!sidebarView && !isClosing}
        closeHandler={() => handleClose()}
      >
        {sidebarView ? <VisibleContainer swapView={swapView} /> : null}
      </Drawer>
    </Fragment>
  )
}
export default SideBar
