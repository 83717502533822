import { createSelector } from 'reselect'

const getDirectionsToPortState = (state) => state.directionsToPort

export const selectDestination = createSelector(
  getDirectionsToPortState,
  (state) => {
    return state.selectedDestination || null
  }
)

export const selectOrigin = createSelector(
  getDirectionsToPortState,
  (state) => {
    return state.selectedOrigin || null
  }
)

export const selectShip = createSelector(getDirectionsToPortState, (state) => {
  return state.selectedShip || null
})

export const selectShipDetails = createSelector(
  getDirectionsToPortState,
  (state) => {
    return state.selectedShipDetails || null
  }
)

export const selectRoute = createSelector(getDirectionsToPortState, (state) => {
  return state.selectedRoute || null
})

export const selectFilter = createSelector(
  getDirectionsToPortState,
  (state) => {
    return state.selectedFilter || null
  }
)

export const selectRoutes = createSelector(
  getDirectionsToPortState,
  (state) => {
    return state.routes || []
  }
)

export const selectSkipShipSelection = createSelector(
  getDirectionsToPortState,
  (state) => {
    return state.skipShipSelection || false
  }
)
