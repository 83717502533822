import React from 'react'
import propTypes from 'prop-types'

import './RoundedCheckbox.scss'

export const RoundedCheckbox = ({ id, label, onChange, ...rest }) => {
  return (
    <div className="rounded-checkbox">
      <input
        id={id}
        className={`rounded-checkbox ${rest?.className ? rest.className : ''}`}
        type="checkbox"
        onChange={onChange}
        {...rest}
      />
      <label className="rounded-checkbox-label" htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

RoundedCheckbox.defaultProps = {
  onChange: () => {},
}

RoundedCheckbox.propTypes = {
  id: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  onChange: propTypes.func,
}
