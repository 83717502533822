import React from 'react'

import { func, string } from 'prop-types'
import Input from '../utils/components/Input'
import StartEnd from '../../../assets/icons/start-end.svg'

import './DestinationAndOriginSearch.scss'
import { useMapContext } from '../MapProvider'
import { useUI } from '../../../context/modalContext'

const DestinationAndOriginSearch = ({
  value,
  searchedDestination,
  onOriginChange,
  onDestinationChange,
  clearDestination,
  clearOrigin,
}) => {
  const { selectedDestination, selectedOrigin, shouldShowOriginInput } =
    useMapContext()

  const { handleClickAnalyticsData } = useUI()

  return (
    <div className="port-and-origin-search-container">
      {shouldShowOriginInput() && (
        <img
          style={{
            marginRight: 8,
          }}
          src={StartEnd}
          alt="start-end"
        />
      )}
      <div className="search-bar-container">
        {shouldShowOriginInput() && (
          <Input
            autoFocus
            disabled={selectedOrigin !== null}
            containerClassNames="mb-2"
            placeholder="From"
            className="origin-search"
            value={selectedOrigin?.address || value}
            onChange={onOriginChange}
            showCancelBtn={selectedOrigin !== null}
            onCancel={() => {
              handleClickAnalyticsData('Clear button')
              clearOrigin()
            }}
          />
        )}
        <Input
          disabled={selectedDestination !== null}
          value={selectedDestination?.label || searchedDestination}
          onChange={onDestinationChange}
          placeholder="Search by home port, ship, or location"
          showCancelBtn={selectedDestination !== null}
          onCancel={() => {
            handleClickAnalyticsData('Clear button')
            clearOrigin()
            clearDestination()
          }}
        />
      </div>
    </div>
  )
}

DestinationAndOriginSearch.defaultProps = {
  value: '',
  searchedDestination: '',
  onOriginChange: () => {},
  onDestinationChange: () => {},
  clearDestination: () => {},
  clearOrigin: () => {},
}

DestinationAndOriginSearch.propTypes = {
  value: string,
  searchedDestination: string,
  onOriginChange: func,
  onDestinationChange: func,
  clearDestination: func,
  clearOrigin: func,
}

export default DestinationAndOriginSearch
