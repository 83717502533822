import queryString from 'query-string'

export const useSSO = () => {
  const params = queryString.parse(window.location.search)
  let ssoParam = ''
  if (params.sso) {
    ssoParam = `?sso=${params.sso}`
  } else if (params.z) {
    ssoParam = `?z=${params.z}`
  } else if (params.cptok) {
    ssoParam = `?cptok=${params.cptok}`
  }

  return ssoParam
}
