import React from 'react'
import { bool, shape, string } from 'prop-types'
import './AnnouncementCard.scss'

import chevronRight from '../../assets/icons/chevron-right.svg'

const AnnouncementCard = ({ announcement }) => (
  <div className="announcement-card card">
    <div className="new-tag-container">
      {announcement.isNew ? <div className="new-tag">New</div> : null}
    </div>
    {announcement.date ? <div className="date">{announcement.date}</div> : null}
    <div className="columns">
      <div className="announcement-title">{announcement.title}</div>
      <div className="link">
        {announcement.url && (
          <>
            <a
              onClick={() => {
                window.utag.link({
                  event_name: announcement.url.includes('.pdf')
                    ? 'download link'
                    : 'click',
                  page_url: window.location.href,
                  category: 'announcements', // set this as "announcements"
                  interaction_type: announcement.url.includes('.pdf')
                    ? 'download link click'
                    : 'announcement click',
                  nav_link_text: announcement.url.includes('.pdf')
                    ? announcement.url.split('/').pop()
                    : 'click', // if the link is to download a pdf file then set this as the pdf file name, example "CEL_2026_27_New_Deployment.pdf". If the link is not a pdf then set this as link text, ex. "View More Information".
                })
              }}
              href={announcement.url}
              target="_blank"
              rel="noreferrer"
            >
              View More Information
            </a>
            <img src={chevronRight} alt="" />
          </>
        )}
      </div>
    </div>
    {announcement.shortDescription ? (
      <div
        className="description"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: announcement.shortDescription }}
      />
    ) : null}
  </div>
)

AnnouncementCard.propTypes = {
  announcement: shape({
    isNew: bool.isRequired,
    date: string.isRequired,
    title: string.isRequired,
    shortDescription: string.isRequired,
    url: string.isRequired,
  }).isRequired,
}

export default AnnouncementCard
