import React, { useState, useRef } from 'react'
import moment from 'moment'
import { bool, func, arrayOf, string } from 'prop-types'

import UseOutsideClick from '../UseOutsideClick/UseOutsideClick'

import './BookOrQuotePackageCard.scss'

const BookOrQuotePackageCard = ({
  title,
  icon,
  dates,
  continueLabel,
  isActive,
  closeHandler,
  buildExternalUrl,
  handleDeploymentQuoteClick,
}) => {
  const [activeDate, setActiveDate] = useState(null)

  const ref = useRef()

  UseOutsideClick(ref, () => {
    if (isActive) {
      closeHandler()
    }
  })

  return (
    <div
      className={`book-or-quote-package-card-component ${
        isActive ? 'active' : 'hidden'
      }`}
      ref={ref}
    >
      <div className="book-or-quote-package-card-component__content">
        <h3 className="book-or-quote-package-card-component__title">{title}</h3>
        <p className="book-or-quote-package-card-component__sail-date-title">
          Select sail date
        </p>
        <div className="book-or-quote-package-card-component__dates">
          {dates.length > 0 &&
            dates.map((date, index) => {
              return (
                <button
                  type="button"
                  key={`date-${index}`}
                  className={`book-or-quote-package-card-component__dates__date ${
                    activeDate === date ? 'active' : 'inactive'
                  }`}
                  onClick={() => setActiveDate(date)}
                >
                  {moment(date).format('MMMM DD, YYYY')}
                </button>
              )
            })}
        </div>
        {activeDate ? (
          <a
            target="_blank"
            rel="noreferrer"
            onClick={handleDeploymentQuoteClick}
            href={buildExternalUrl(activeDate)}
            className="book-or-quote-package-card-component__continue"
          >
            {continueLabel}
          </a>
        ) : (
          ''
        )}
      </div>
      <img
        src={icon}
        alt={title}
        className="book-or-quote-package-card-component__icon"
      />
    </div>
  )
}

BookOrQuotePackageCard.propTypes = {
  title: string.isRequired,
  icon: string.isRequired,
  dates: arrayOf(string),
  continueLabel: string.isRequired,
  isActive: bool,
  closeHandler: func.isRequired,
  buildExternalUrl: func,
  handleDeploymentQuoteClick: func,
}

BookOrQuotePackageCard.defaultProps = {
  isActive: false,
  dates: [],
  buildExternalUrl: () => {},
  handleDeploymentQuoteClick: () => {},
}

export default BookOrQuotePackageCard
