import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { func } from 'prop-types'
import MapAndScheduleMenuItem from '../../components/MapAndScheduleMenuItem/MapAndScheduleMenuItem'
import regionsIcon from '../../assets/icons/menu-regions-icon.svg'

import selectRegionListData from '../../redux/selectors/selectRegionListData'
import {
  setViewOrigin,
  zoomToAndCloseSidebar,
} from '../../redux/view/viewActions'
import { ZoomLevel } from '../../configuration/constants'

import './RegionsContainer.scss'
import { useUI } from '../../context/modalContext'

const RegionsContainer = ({ swapView }) => {
  const { navigateTo } = useUI()
  const dispatch = useDispatch()
  const regions = useSelector(selectRegionListData)
  const handleScheduleClick = (region) => {
    dispatch(setViewOrigin('Regions'))
    swapView('region', region.shortCode)
  }

  const handleHomePort = (region) => {
    navigateTo('/directions-to-port', {
      region,
      type: 'region',
    })
  }

  const onMenuItemClick = (menuItem, region) => {
    window.utag.link({
      event_name: 'click',
      page_url: window.location.href,
      category: 'regions result',
      interaction_type: 'region click',
      nav_link_text: menuItem,
      region,
    })
  }

  return (
    <div className="regions-container">
      <header>
        <div className="title-row">
          <img src={regionsIcon} alt="" />
          <div className="label">Regions</div>
          <div />
        </div>
      </header>
      <main>
        {regions.map((region) => {
          return (
            <MapAndScheduleMenuItem
              key={region.shortCode}
              name={region.name}
              onClickSchedule={() => {
                onMenuItemClick('Schedule', region.name)
                handleScheduleClick(region)
              }}
              onClickViewMap={() => {
                onMenuItemClick('View on Map', region.name)
                dispatch(
                  zoomToAndCloseSidebar(ZoomLevel.REGION, region.shortCode)
                )
              }}
              hideViewOnMap={!region.enableViewOnMap}
              homePort="Home Port"
              onClickHomePort={() => {
                onMenuItemClick('Home Port', region.name)
                handleHomePort(region)
              }}
            />
          )
        })}
      </main>
    </div>
  )
}

RegionsContainer.propTypes = {
  swapView: func.isRequired,
}

export default RegionsContainer
