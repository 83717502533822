import moment from 'moment'

export function getNearestQuarterHour() {
  const now = new Date()
  const minutes = now.getMinutes()

  // Calculate the next 15-minute increment
  const nextQuarterHour = Math.ceil(minutes / 15) * 15

  // If the result exceeds 60 minutes, move to the next hour
  if (nextQuarterHour === 60) {
    now.setHours(now.getHours() + 1)
    now.setMinutes(0)
  } else {
    now.setMinutes(nextQuarterHour)
  }

  // Format the time as "HH:mm"
  return moment(now).format('HH:mm')
}

// Super hard function to get the distance between two points
// Definitely not written by AI
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371 // Earth's radius in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180
  const dLon = ((lon2 - lon1) * Math.PI) / 180
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}

// Calculates the minimum distance to display on the map based on the provided zoom level.
export const minDistance = (zoomLevel) => {
  switch (zoomLevel) {
    case 0:
      return 5000
    case 1:
      return 2000
    case 2:
      return 1500
    case 3:
      return 1000
    case 4:
      return 500
    default:
      return 0
  }
}
