import React from 'react'
import { func, arrayOf, shape, string } from 'prop-types'

import MapSearchResult from './MapSearchResult'

import './MapSearch.scss'

import searchIcon from '../../assets/icons/search-icon.svg'

const MapSearch = ({ updateSearchTerm, searchResults, handleSelection }) => {
  const searchPlaceholder = 'Search by Ship, Region or Port'

  const autoSearch = (e) => {
    window.utag.link({
      event_name: 'map_control_search',
      page_url: window.location.href,
      search_bar_name: 'Filter search bar',
      search_term: e.target.value,
    })
    if (e.target.value.length <= 1) {
      updateSearchTerm('')
    } else {
      updateSearchTerm(e.target.value)
    }
  }

  return (
    <div className="component-map-search">
      <div className="component-map-search-container">
        <div className="component-map-search-input-wrapper">
          <div className="component-map-search-input">
            <img
              src={searchIcon}
              alt="search-icon"
              className="component-map-search-input__icon"
            />
            <input
              type="text"
              name="search"
              autoComplete="off"
              placeholder={searchPlaceholder}
              className="component-map-search-input__input"
              onChange={autoSearch}
            />
          </div>
        </div>
        {searchResults.length > 0 ? (
          <div className="component-map-search-results">
            {searchResults.map((searchItem, idx) => (
              <MapSearchResult
                key={idx}
                searchResult={searchItem}
                onSelect={handleSelection}
              />
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

MapSearch.propTypes = {
  handleSelection: func.isRequired, // params: type, shortCode
  updateSearchTerm: func.isRequired,
  searchResults: arrayOf(
    shape({
      name: string.isRequired,
      type: string.isRequired,
    })
  ),
}

MapSearch.defaultProps = {
  searchResults: [],
}

export default MapSearch
