import React from 'react'
import chevronRight from '../../../assets/icons/chevron-right.svg'

import './RoutesList.scss'
import Steps from './Steps'
import { useMapContext } from '../MapProvider'
import { useUI } from '../../../context/modalContext'

const RoutesList = () => {
  const {
    selectedOrigin,
    selectedRoute,
    onSelectRoute,
    routes,
    directionsRenderer,
    travelMode,
  } = useMapContext()
  const { handleClickAnalyticsData } = useUI()

  const getTransitOrWalkingSummary = (route) => {
    const lastTransitRoute = route.legs[0].steps
      .filter((leg) => leg.travel_mode === 'TRANSIT')
      .at(-1)
    const lastWalkingRoute = route.legs[0].steps
      .filter((leg) => leg.travel_mode === 'WALKING')
      .at(-1)

    return lastTransitRoute
      ? lastTransitRoute.instructions
      : lastWalkingRoute?.instructions || ''
  }

  return (
    <>
      {selectedRoute && (
        <div className="selected-route-container">
          <div className="selected-route-highlight">
            <div className="selected-route-summary">
              {travelMode === 'Transit'
                ? getTransitOrWalkingSummary(selectedRoute)
                : selectedRoute.summary}
            </div>
            <div className="route-info">
              <div className="route-duration">
                {selectedRoute.legs[0].duration.text}
              </div>
              <div className="route-distance">
                {selectedRoute.legs[0].distance.text}
              </div>
            </div>
          </div>
          <Steps route={selectedRoute} />
        </div>
      )}
      {!selectedRoute && selectedOrigin && (
        <div className="routes-list-container">
          {routes.length === 0 && (
            <div className="not-found">No routes found</div>
          )}
          {routes.map((route, index) => (
            <div className="route-list-item" key={route.summary}>
              <div
                className="route-container"
                onClick={() => {
                  handleClickAnalyticsData(route.summary || 'Route')
                  onSelectRoute(route)
                  if (directionsRenderer) {
                    directionsRenderer.setRouteIndex(index)
                  }
                }}
              >
                <div className="route-summary">
                  {travelMode === 'Transit'
                    ? getTransitOrWalkingSummary(route)
                    : route.summary}
                </div>
                <div className="route-info">
                  <div className="route-duration">
                    {route.legs[0].duration.text}
                  </div>
                  <div className="route-distance">
                    {route.legs[0].distance.text}
                  </div>
                </div>
                <img src={chevronRight} alt="" />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default RoutesList
