import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useSSO } from '../hooks/useSSO'

const initialState = {
  displayModal: false,
  modalView: 'LANDING_MODAL',
}

export const UIContext = React.createContext(initialState)
UIContext.displayName = 'UIContext'

const modalsReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
        modalView: action.view,
      }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      }
    }
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
      }
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
      }
    }
    default: {
      return { ...state }
    }
  }
}

export const UIProvider = (props) => {
  const [state, dispatch] = React.useReducer(modalsReducer, initialState)

  const setModalView = (view) => dispatch({ type: 'SET_MODAL_VIEW', view })

  const openModal = (modalID) => dispatch({ type: 'OPEN_MODAL', view: modalID })
  const closeModal = () => dispatch({ type: 'CLOSE_MODAL' })

  const openSidebar = () => dispatch({ type: 'OPEN_SIDEBAR' })
  const closeSidebar = () => dispatch({ type: 'CLOSE_SIDEBAR' })
  const history = useHistory()

  const navigateTo = (path, newParams = {}) => {
    const ssoParam = useSSO()
    history.push(`${path}${ssoParam}`, newParams)
  }

  const handleClickAnalyticsData = (
    clickText,
    linkUrl = '/directions-to-port'
  ) => {
    const payload = {
      event_name: 'navbar_click',
      nav_link_text: clickText,
      interaction_type: 'sidebar',
      category: 'navigation',
      link_url: linkUrl,
      page_url: '/directions-to-port',
      event211: 1,
    }

    window.utag.link(payload)
  }

  const value = useMemo(
    () => ({
      ...state,
      setModalView,
      openModal,
      closeModal,
      openSidebar,
      closeSidebar,
      handleClickAnalyticsData,
      navigateTo,
    }),
    [state]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = React.useContext(UIContext)
  if (context === undefined) {
    throw new Error('useUI must be used within a UIProvider')
  }
  return context
}
