import React from 'react'
import propTypes from 'prop-types'

import Close from '../../assets/icons/close-circle-x.png'

import './Modal.scss'

const Modal = ({ title, onClose, children }) => {
  return (
    <div className="modal-overlay dtp-modal-overlay">
      <div className="modal dtp-modal">
        <div onClick={onClose} className="dtp-modal-close">
          <img src={Close} alt="Close Email Success" />
        </div>
        <div className="dtp-modal-body">
          <div className="dtp-modal-title">
            <span className="email-title">{title}</span>
          </div>
          <>{children}</>
        </div>
      </div>
    </div>
  )
}

Modal.defaultProps = {
  onClose: () => {},
  children: null,
}

Modal.propTypes = {
  title: propTypes.string.isRequired,
  onClose: propTypes.func,
  children: propTypes.arrayOf(propTypes.node),
}

export default Modal
