export const BRANDS = [
  {
    key: 'C',
    label: 'Celebrity Cruises',
  },
  {
    key: 'R',
    label: 'Royal Caribbean',
  },
]
