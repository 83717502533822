import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useSSO } from '../../hooks/useSSO'

import Close from '../../assets/icons/close-circle-x.png'

const CloseEmail = ({ ...rest }) => {
  const ssoParam = useSSO()

  return (
    <Link
      {...rest}
      className={`${rest.className ? rest.className : ''}`}
      to={{
        pathname: '/directions-to-port',
        search: ssoParam,
      }}
    >
      <img src={Close} alt="Close Email Preview" />
    </Link>
  )
}

export default CloseEmail
