import React from 'react'
import AnimatedWaterTop from '../../../assets/icons/animated-water-top.svg'
import AnimatedWaterBottom from '../../../assets/icons/animated-water-bottom.svg'
import AnimatedShip from '../../../assets/icons/animated-ship.svg'

import './Loading.scss'

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="svg-container">
        <img src={AnimatedShip} alt="animated-ship" />
        <img src={AnimatedWaterTop} alt="animated-water-top" />
        <img src={AnimatedWaterBottom} alt="animated-water-bottom" />
      </div>
      Loading...
    </div>
  )
}

export default Loading
