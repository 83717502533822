import { arrayOf, func, string } from 'prop-types'
import React, { useState } from 'react'
import BoldedText from '../utils/components/BoldedText'
import AnchorIcon from '../../../assets/icons/anchor-icon.svg'
import AnchorIconWhite from '../../../assets/icons/anchor-icon-white.svg'
import ShipIcon from '../../../assets/icons/dtp-ship-icon.svg'
import ShipIconWhite from '../../../assets/icons/dtp-ship-icon-white.svg'

import './DestinationList.scss'
import { useMapContext } from '../MapProvider'
import { useUI } from '../../../context/modalContext'

const DestinationList = ({
  searchedDestination,
  resultsToShow,
  setSearchedDestination,
  onFilter,
}) => {
  const [selectedFilter, setSelectedFilter] = useState('all')

  const { selectedDestination, onDestinationSelect } = useMapContext()

  const { handleClickAnalyticsData } = useUI()

  return !selectedDestination && searchedDestination !== '' ? (
    <div className="ports-list-container">
      <div className="ports-list">
        {resultsToShow.length === 0 ? (
          <div>No results found</div>
        ) : (
          resultsToShow.map((result) => (
            <div
              className="port-item"
              onClick={() => {
                handleClickAnalyticsData(`${result.label}`)
                setSearchedDestination(result.label)
                onDestinationSelect(result)
              }}
              key={result.id}
            >
              <img
                width={28}
                src={result.type === 'ship' ? ShipIcon : AnchorIcon}
                alt="pin-icon"
              />
              <span
                style={{
                  marginLeft: 16,
                }}
              >
                <BoldedText
                  text={result.label}
                  shouldBeBold={searchedDestination}
                />
              </span>
            </div>
          ))
        )}
      </div>
      <div className="filter-container">
        <div>Filter results:</div>
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            onClick={() => {
              handleClickAnalyticsData('All')
              setSelectedFilter('all')
              onFilter('all')
            }}
            className={`filter-item ${
              selectedFilter === 'all' ? 'active' : ''
            }`}
          >
            All
          </div>
          <div
            onClick={() => {
              handleClickAnalyticsData('Ports')
              setSelectedFilter('ports')
              onFilter('homeport')
            }}
            className={`filter-item ${
              selectedFilter === 'ports' ? 'active' : ''
            }`}
          >
            Ports
            <img
              style={{
                marginLeft: 4,
                color: 'white',
              }}
              width={24}
              src={selectedFilter === 'ports' ? AnchorIconWhite : AnchorIcon}
              alt="pin-icon"
            />
          </div>
          <div
            onClick={() => {
              handleClickAnalyticsData('Ships')
              setSelectedFilter('ships')
              onFilter('ship')
            }}
            className={`filter-item ${
              selectedFilter === 'ships' ? 'active' : ''
            }`}
          >
            Ships
            <img
              style={{
                marginLeft: 4,
              }}
              width={24}
              src={selectedFilter === 'ships' ? ShipIconWhite : ShipIcon}
              alt="pin-icon"
            />
          </div>
        </div>
      </div>
    </div>
  ) : null
}

DestinationList.defaultProps = {
  searchedDestination: '',
  resultsToShow: [],
  setSearchedDestination: () => {},
  onFilter: () => {},
}

DestinationList.propTypes = {
  searchedDestination: string,
  resultsToShow: arrayOf(Array),
  setSearchedDestination: func,
  onFilter: func,
}

export default DestinationList
