import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import propTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { postDTPEmail } from '../../api/api'
import RoundedInput from '../../components/RoundedInput/RoundedInput'
import { RoundedButton } from '../../components/RoundedButton/RoundedButton'
import { RoundedCheckbox } from '../../components/RoundedCheckbox/RoundedCheckbox'
import SelectWithLabel from '../DirectionsToPort/utils/components/SelectWithLabel'
import { BRANDS } from './utils/constants'

import { ReactComponent as PaperAirplane } from '../../assets/icons/paper-airplane.svg'

import './EmailInfo.scss'
import { useUI } from '../../context/modalContext'

const validationSchema = Yup.object().shape({
  recipients: Yup.string().required('recipients email is required'),
  clientName: Yup.string().required('client name is required'),
  agentName: Yup.string().required('agent name is required'),
  agencyName: Yup.string().required('agency name is required'),
  agencyEmail: Yup.string().required('agency email is required'),
})

const defaultValues = {
  recipients: '',
  copy: false,
  clientName: '',
  agentName: '',
  agencyName: '',
  agencyEmail: '',
  brand: '',
}

const EmailInfo = ({ onChange, onError, ...rest }) => {
  const agentName = useSelector((state) => state?.authentication?.agent?.name)
  const agencyName = useSelector((state) => state?.authentication?.agency?.name)
  const agencyEmail = useSelector(
    (state) => state?.authentication?.agent?.email
  )
  const [errors, setErrors] = useState({})
  const { state } = useLocation()
  const mapsLink = state?.mapsLink || ''
  const origin = state?.origin || ''
  const destination = state?.destination || ''
  const brand = state?.details?.brandCode || ''
  const { navigateTo } = useUI()

  const [values, setValues] = useState({
    ...defaultValues,
    agentName,
    agencyName,
    agencyEmail,
    brand,
  })

  useEffect(() => {
    onChange(values)
  }, [values])

  const handleValidation = async () => {
    try {
      // Validate values against the schema
      await validationSchema.validate(values, {
        abortEarly: false,
      })
      // clear out errors
      setErrors({})
      return true
    } catch (err) {
      const validationErrors = {}
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message
      })
      setErrors(validationErrors)
      return false
    }
  }

  const handleChange = async (e) => {
    const { id, value, type } = e.target

    if (type === 'checkbox') {
      setValues({ ...values, [id]: e.target.checked })
    } else {
      setValues({ ...values, [id]: value })
    }

    if (value && value !== '') setErrors({ ...errors, [id]: null })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    window.utag.link({
      event_name: 'form_submit',
      page_url: window.location.href,
      form_name: 'send email form',
      nav_link_text: 'Send',
    })
    const valid = await handleValidation()
    const hasParams = mapsLink && origin && destination

    if (valid && hasParams) submitEmail()
  }

  const submitEmail = async () => {
    const sendTo = [
      ...values.recipients
        .replace(/\s/g, '')
        .split(',')
        .filter((item) => item !== ''),
    ]

    const recipients = values?.copy ? [...sendTo, values.agencyEmail] : sendTo

    const jsonTemplate = {
      brandCode: values.brand,
      guest: {
        name: values.clientName,
        email: recipients,
      },
      agent: {
        name: values.agentName,
        agency: values.agencyName,
        email: values.agencyEmail,
      },
      directions: {
        from: origin,
        to: destination,
        portPhoneNumber: '',
        url: mapsLink,
      },
    }

    try {
      // send email request
      await postDTPEmail(jsonTemplate)

      navigateTo('/directions-to-port', {
        emailSent: true,
        terminal: destination,
        recipients,
      })
    } catch (err) {
      onError()
    }
  }

  return (
    <div
      {...rest}
      className={`email-info ${rest.className ? rest.className : ''}`}
    >
      <form onSubmit={handleSubmit}>
        <div className="email-row">
          <span className="email-title">Send via Email</span>
        </div>
        <div className="email-row">
          <SelectWithLabel
            id="brand"
            placeholder="Select brand"
            label="Brand:"
            values={BRANDS.map((b) => ({
              key: b.key,
              label: b.label,
            }))}
            selectedValue={values.brand}
            onChange={async (e) => {
              await handleChange(e)
            }}
            disabled={values.brand && values.brand !== ''}
          />
        </div>
        <div className="email-row">
          <RoundedInput
            id="recipients"
            label="Recipients:"
            type="text"
            value={values.recipients}
            placeholder="Enter email addresses separated by a comma"
            onChange={async (e) => {
              await handleChange(e)
            }}
          />
          {errors.recipients && (
            <p className="email-error">{errors.recipients}</p>
          )}
          <div className="email-copy">
            <RoundedCheckbox
              id="copy"
              label="Send me a copy"
              onChange={async (e) => {
                await handleChange(e)
              }}
            />
          </div>
        </div>
        <div className="email-row">
          <RoundedInput
            id="clientName"
            label="Clients Name:"
            type="text"
            value={values.clientName}
            placeholder="Client's Name"
            onChange={async (e) => {
              await handleChange(e)
            }}
          />
          {errors.clientName && (
            <p className="email-error">{errors.clientName}</p>
          )}
        </div>
        <div className="email-row">
          <RoundedInput
            id="agentName"
            label="Agent Name:"
            value={values.agentName}
            type="text"
            placeholder="[Agents Name]"
            onChange={async (e) => {
              await handleChange(e)
            }}
          />
          {errors.agentName && (
            <p className="email-error">{errors.agentName}</p>
          )}
        </div>
        <div className="email-row">
          <RoundedInput
            id="agencyName"
            label="Agency Name:"
            type="text"
            value={values.agencyName}
            placeholder="[Agency Name]"
            onChange={async (e) => {
              await handleChange(e)
            }}
          />
          {errors.agencyName && (
            <p className="email-error">{errors.agencyName}</p>
          )}
        </div>
        <div className="email-row">
          <RoundedInput
            id="agencyEmail"
            label="Agent email address:"
            type="text"
            value={values.agencyEmail}
            placeholder="[Agent Email Address]"
            onChange={async (e) => {
              await handleChange(e)
            }}
          />
          {errors.agencyEmail && (
            <p className="email-error">{errors.agencyEmail}</p>
          )}
        </div>
        <div className="row-center">
          <RoundedButton
            className="send-email"
            label="Send"
            icon={<PaperAirplane />}
          />
        </div>
      </form>
    </div>
  )
}

EmailInfo.defaultProps = {
  onChange: () => {},
  onError: () => {},
}

EmailInfo.propTypes = {
  onChange: propTypes.func,
  onError: propTypes.func,
}

export default EmailInfo
