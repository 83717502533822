import { createSelector } from 'reselect'
import moment from 'moment'
import { uniq } from 'lodash'
import { selectSidebarViewResource } from '../view/viewSelectors'
import { selectSailings, selectPackages } from '../sailings/sailingsSelectors'
import { selectRegionsIndexed } from '../regions/regionsSelectors'
import { selectPortsIndexed } from '../ports/portsSelectors'
import { selectShipDeploymentsFilter } from '../filters/filtersSelectors'

const parseYearMonth = (yearMonth) => {
  return yearMonth.split('-')
}

const selectShipViewDeployments = createSelector(
  selectSidebarViewResource,
  selectSailings,
  selectPackages,
  selectRegionsIndexed,
  selectPortsIndexed,
  selectShipDeploymentsFilter,
  (state, returnAllYears) => returnAllYears,
  (shipCode, sailings, packages, regions, ports, filter, returnAllYears) => {
    const resultPackages = {}
    const yearMonths = Object.keys(sailings)

    yearMonths.forEach((yearMonth) => {
      const monthSailingData = sailings[yearMonth][shipCode]
      if (!monthSailingData) {
        return
      }

      const [year, month] = parseYearMonth(yearMonth)
      if (!returnAllYears && filter.year && filter.year !== year) {
        return
      }

      monthSailingData.forEach((itinerary) => {
        const { packageId, sailDate } = itinerary
        const packageData = packages[packageId]

        if (filter.month.length && !filter.month.includes(month)) {
          return
        }

        if (!resultPackages[packageId]) {
          if (
            filter.region.length &&
            !filter.region.includes(packageData.destinationCode)
          ) {
            return
          }
          if (
            filter.portOfCall.length &&
            !filter.portOfCall.some((portCode) =>
              packageData.ports.includes(portCode)
            ) &&
            !filter.portOfCall.some(
              (portCode) => portCode === packageData?.departurePort
            )
          ) {
            return
          }

          resultPackages[packageId] = {
            name:
              packageData?.itinerary?.description || packageData.description,
            brand: packageData.brand,
            shipCode: packageData.shipCode,
            shortCode: packageId,
            sailDates: [],
            region: regions[packageData.destinationCode]?.name,
            ports: packageData.ports.map((portCode) => {
              // TODO(ebarrett): This should be removed, once the data is fixed
              if (!ports[portCode]) {
                return `MISSING ${portCode}`
              }
              return ports[portCode].name
            }),
            departurePort: ports[packageData.departurePort]?.name || '',
            departurePortCode: packageData.departurePort,
          }
        }
        resultPackages[packageId].sailDates.push(sailDate)
      })
    })

    return Object.values(resultPackages)
  }
)

export const selectAllDeploymentYears = createSelector(
  (state) => selectShipViewDeployments(state, true),
  (deployments) => {
    const years = deployments?.length
      ? deployments.map((deployment) =>
          uniq(
            deployment?.sailDates?.map((date) => moment(date).format('YYYY')) ||
              []
          )
        )
      : []
    return uniq(years.flat())
  }
)

export default selectShipViewDeployments
