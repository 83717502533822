import React, { useContext, useEffect, useState } from 'react'
import { Map } from '@vis.gl/react-google-maps/dist/index.umd.js' // eslint-disable-line import/extensions
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import Sidebar from './Sidebar/Sidebar'
import Markers from './Markers/Markers'
import MobileHeader from './MobileHeader/MobileHeader'
import { MapContext } from './MapProvider'
import ExitButton from './ExitButton/ExitButton'
import Filters from './Filters/Filters'
import EmailSent from './EmailSent/EmailSent'
import { minDistance, calculateDistance } from './utils/functions'
import { fetchSso } from '../../redux/authentication/authentication'
import analyticsPageView from '../../utilities/analytics/analyticsPageView'

import './DirectionsToPortContainer.scss'

const DirectionsToPortContainer = () => {
  const { map, allData, regionData, selectedFilter, zoomIn, zoomOut } =
    useContext(MapContext)
  const { state } = useLocation()
  const [emailSent, setEmailSent] = useState(state?.emailSent || false)
  const [portsToShow, setPortsToShow] = useState([])
  const [regionsToShow, setRegionsToShow] = useState([])
  const dispatch = useDispatch()

  const filterPortsByZoom = (zoomLevel) => {
    const ports = allData.filter(
      (portOrRegion) => portOrRegion.type === 'homeport'
    )
    setPortsToShow(
      ports.filter((port1, index1) => {
        if (port1.rank === 2) return true
        return !ports.some((port2, index2) => {
          if (port1 === port2) return false
          const distance = calculateDistance(
            port1.lat,
            port1.lng,
            port2.lat,
            port2.lng
          )
          if (port2.rank === 2 && distance < minDistance(zoomLevel)) return true
          return distance < minDistance(zoomLevel) && index1 > index2
        })
      })
    )
  }

  const filterRegionsByZoom = (zoomLevel) => {
    setRegionsToShow(
      regionData.filter((region1, index1) => {
        if (region1.rank === 2) return true
        return !regionData.some((region2, index2) => {
          if (region1 === region2) return false
          const distance = calculateDistance(
            region1.lat,
            region1.lng,
            region2.lat,
            region2.lng
          )
          if (region2.rank === 2 && distance < minDistance(zoomLevel))
            return true
          return distance < minDistance(zoomLevel) && index1 > index2
        })
      })
    )
  }

  const filterData = (zoomLevel) => {
    filterRegionsByZoom(zoomLevel)
    filterPortsByZoom(zoomLevel)
  }

  useEffect(() => {
    const params = queryString.parse(window.location.search)
    const ssoParam = params.sso || params.z || params.cptok

    if (!ssoParam) {
      window.location.href = process.env.REACT_APP_CRUISING_POWER_LOGIN
      return
    }

    dispatch(fetchSso(ssoParam)).then(analyticsPageView)
  }, [])

  useEffect(() => {
    if (map) filterData(map.getZoom())
  }, [allData])

  const closeModal = () => {
    setEmailSent(false)
  }

  return (
    <div className="dtp-container">
      {emailSent && (
        <EmailSent
          destination={state?.terminal}
          recipients={state?.recipients}
          onClose={closeModal}
        />
      )}
      {map && (
        <>
          <MobileHeader />
          <Sidebar />
        </>
      )}
      <ExitButton />
      <Filters />
      <Map
        mapId={process.env.REACT_APP_GOOGLE_MAPS_ID}
        style={{ width: '100vw', height: '100vh' }}
        defaultZoom={3}
        minZoom={2}
        defaultCenter={{ lat: 22.54992, lng: -60 }}
        gestureHandling="greedy"
        restriction={{
          latLngBounds: {
            north: 85,
            south: -85,
            west: -180,
            east: 180,
          },
          strictBounds: true,
        }}
        disableDefaultUI
        onZoomChanged={() => filterData(map.getZoom())}
      >
        <div className="zoom-controls">
          <div className="zoom-button zoom-in" onClick={zoomIn}>
            &#43;
          </div>
          <div className="zoom-button zoom-out" onClick={zoomOut}>
            &#x2212;
          </div>
        </div>
        <Markers
          ports={selectedFilter === 'regions' ? regionsToShow : portsToShow}
        />
      </Map>
    </div>
  )
}

export default DirectionsToPortContainer
