import React from 'react'
import propTypes from 'prop-types'

import './RoundedInput.scss'

const RoundedInput = ({
  id,
  label,
  type,
  placeholder,
  onChange,
  optional,
  ...rest
}) => {
  return (
    <div>
      <label className="rounded-input-label" htmlFor={id}>
        {label}
        {optional && <i> (optional)</i>}
      </label>
      {type === 'text' && (
        <input
          className="rounded-input"
          id={id}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          {...rest}
        />
      )}
      {type === 'textarea' && (
        <textarea
          className="rounded-textarea"
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          {...rest}
        />
      )}
    </div>
  )
}

RoundedInput.defaultProps = {
  onChange: () => {},
  placeholder: '',
  optional: false,
}

RoundedInput.propTypes = {
  id: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  placeholder: propTypes.string,
  onChange: propTypes.func,
  optional: propTypes.bool,
}

export default RoundedInput
