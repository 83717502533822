import React from 'react'

import './Steps.scss'
import { objectOf } from 'prop-types'

const Steps = ({ route }) => {
  return (
    <div className="step-list-container">
      {route.legs[0].steps.map((step, index) => (
        <div className="step-list-item" key={index}>
          <div className="step-container">
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: step.instructions }}
              className="route-summary"
            />
            <div className="step-info">
              <div>
                {step.duration.text} ({step.distance.text})
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

Steps.propTypes = {
  route: objectOf(Object).isRequired,
}

export default Steps
