import React from 'react'
import { bool, func, string } from 'prop-types'
import Close from '../../../../assets/icons/close-circle-x.png'

import './Input.scss'

const Input = ({
  placeholder,
  onChange,
  value,
  containerClassNames,
  showCancelBtn,
  onCancel,
  ...rest
}) => {
  return (
    <div className={`input-container ${containerClassNames}`}>
      <input
        style={{ outline: 'none' }}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type="text"
        {...rest}
      />
      {showCancelBtn && (
        <div onClick={onCancel} className="close-btn">
          <img width={28} src={Close} alt="Close Email Preview" />
        </div>
      )}
    </div>
  )
}

Input.defaultProps = {
  placeholder: '',
  onChange: () => {},
  containerClassNames: '',
  showCancelBtn: false,
  onCancel: () => {},
}

Input.propTypes = {
  placeholder: string,
  onChange: func,
  value: string.isRequired,
  containerClassNames: string,
  showCancelBtn: bool,
  onCancel: func,
}

export default Input
