import triggerAnalyticsEvent from './triggerAnalyticsEvent'

const analyticsPageView = (dispatchEvent) => {
  const analyticsEvent = 'shipLocatorPageView'
  const data = dispatchEvent?.payload
  const {
    agent,
    agency,
    transactionData: { defaultCurrencyCode },
  } = data

  const pageSection =
    window.location.pathname.split('/')[1] !== 'protected'
      ? window.location.pathname.split('/')[1]
      : window.location.pathname.split('/')[2]

  window.utag.view({
    page_name: window.location.pathname.replaceAll('/', ': '), // take pathname, replace "/" with ": " and all lowercase. example: secure.cruisingpower.com/protected/groups/OneStop.do (protected: groups: onestop)
    page_section: pageSection, // take the value of first path, if first path is protected, don't use that at secction. example: secure.cruisingpower.com/protected/groups/OneStop.do (groups)
    agent_id: agent?.rccl_id, // populate with the agent id when TA logged in. example "1369916"
    agency_name: agency?.name, // populate with the agent's agency name when logged in. example "CRUISINGPOWER"
    agent_country: agent?.country, // populate with the agent's country when logged in. example "USA"
    site_language: document.documentElement.lang.toUpperCase(), // populate with the site language. example "EN"
    currencyCode: defaultCurrencyCode,
  })

  triggerAnalyticsEvent(analyticsEvent, {
    agentId: agent?.rccl_id,
    agentName: agent?.name,
    agentEmail: agent?.email,
    agencyName: agency?.name,
  })
}

export default analyticsPageView
