export const actionTypes = {
  SET_ORIGIN: 'directionsToPort/SET_ORIGIN',
  SET_DESTINATION: 'directionsToPort/SET_DESTINATION',
  SET_SHIP: 'directionsToPort/SET_SHIP',
  SET_SHIP_DETAILS: 'directionsToPort/SET_SHIP_DETAILS',
  SET_ROUTE: 'directionsToPort/SET_ROUTE',
  SET_ROUTES: 'directionsToPort/SET_ROUTES',
  SET_FILTER: 'directionsToPort/SET_FILTER',
  SKIP_SHIP_SELECTION: 'directionsToPort/SKIP_SHIP_SELECTION',
  RESET_INITIAL_STATE: 'directionsToPort/RESET_INITIAL_STATE',
}

export const setSelectedOrigin = (origin) => (dispatch) =>
  dispatch({ type: actionTypes.SET_ORIGIN, payload: origin })

export const setSelectedDestination = (destination) => (dispatch) =>
  dispatch({ type: actionTypes.SET_DESTINATION, payload: destination })

export const setSelectedShip = (ship) => (dispatch) =>
  dispatch({ type: actionTypes.SET_SHIP, payload: ship })

export const setSelectedShipDetails = (details) => (dispatch) =>
  dispatch({ type: actionTypes.SET_SHIP_DETAILS, payload: details })

export const setSelectedRoute = (route) => (dispatch) =>
  dispatch({ type: actionTypes.SET_ROUTE, payload: route })

export const setRoutes = (routes) => (dispatch) =>
  dispatch({ type: actionTypes.SET_ROUTES, payload: routes })

export const setSelectedFilter = (filter) => (dispatch) =>
  dispatch({ type: actionTypes.SET_FILTER, payload: filter })

export const setSkipShipSelection = (skip) => (dispatch) =>
  dispatch({ type: actionTypes.SKIP_SHIP_SELECTION, payload: skip })

export const resetInitialState = () => (dispatch) =>
  dispatch({ type: actionTypes.RESET_INITIAL_STATE })
