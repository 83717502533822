import { actionTypes } from './directionsToPortActions'

const initialState = {
  selectedOrigin: null,
  selectedDestination: null,
  selectedShip: null,
  selectedShipDetails: null,
  selectedRoute: null,
  selectedFilter: 'regions',
  routes: [],
  skipShipSelection: false,
}

function directionsToPortReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_DESTINATION:
      return { ...state, selectedDestination: action.payload }
    case actionTypes.SET_ORIGIN:
      return { ...state, selectedOrigin: action.payload }
    case actionTypes.SET_SHIP:
      return { ...state, selectedShip: action.payload }
    case actionTypes.SET_SHIP_DETAILS:
      return { ...state, selectedShipDetails: action.payload }
    case actionTypes.SET_ROUTE:
      return { ...state, selectedRoute: action.payload }
    case actionTypes.SET_ROUTES:
      return { ...state, routes: action.payload }
    case actionTypes.SET_FILTER:
      return { ...state, selectedFilter: action.payload }
    case actionTypes.SKIP_SHIP_SELECTION:
      return { ...state, skipShipSelection: action.payload }
    case actionTypes.RESET_INITIAL_STATE:
      return initialState
    default:
      return state
  }
}

export default directionsToPortReducer
