import React from 'react'
import { string, func, arrayOf } from 'prop-types'
import './SelectWithLabel.scss'

const SelectWithLabel = ({
  label,
  values,
  placeholder,
  selectedValue,
  onChange,
  ...rest
}) => {
  const mapValuesToOptions = (localValues) =>
    localValues.map((value) => (
      <option key={value.key} value={value.key}>
        {value.label}
      </option>
    ))

  return (
    <div className="select-with-label">
      <label htmlFor={`select-with-label-${label}`}>
        <div className="label-text">{label}</div>
        <select
          id={`select-with-label-${label}`}
          value={selectedValue}
          onChange={onChange}
          {...rest}
        >
          {placeholder && (
            <option key="" value="" disabled>
              {placeholder}
            </option>
          )}
          {mapValuesToOptions(values)}
        </select>
      </label>
    </div>
  )
}

SelectWithLabel.propTypes = {
  label: string.isRequired,
  values: arrayOf(Array).isRequired,
  placeholder: string,
  selectedValue: string,
  onChange: func,
}

SelectWithLabel.defaultProps = {
  placeholder: null,
  selectedValue: {},
  onChange: () => null,
}

export default SelectWithLabel
