import React, { createContext, useContext, useState, useEffect } from 'react'
import {
  useMap,
  useMapsLibrary,
  useApiIsLoaded,
} from '@vis.gl/react-google-maps/dist/index.umd.js' // eslint-disable-line import/extensions
import { useDispatch, useSelector } from 'react-redux'
import { node } from 'prop-types'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import selectPortPinData from '../../redux/selectors/selectPortPinData'
import selectRegionPinData from '../../redux/selectors/selectRegionPinData'
import Loading from './Loading/Loading'
import {
  selectDestination,
  selectFilter,
  selectOrigin,
  selectRoute,
  selectRoutes,
  selectShip,
  selectShipDetails,
  selectSkipShipSelection,
} from '../../redux/directions-to-port/directionsToPortSelectors'

import {
  resetInitialState,
  setRoutes,
  setSelectedDestination,
  setSelectedFilter,
  setSelectedOrigin,
  setSelectedRoute,
  setSelectedShip,
  setSelectedShipDetails,
  setSkipShipSelection,
} from '../../redux/directions-to-port/directionsToPortActions'
import { selectSailings } from '../../redux/sailings/sailingsSelectors'
import { getNearestQuarterHour } from './utils/functions'
import { getPortsFromAEM } from '../../api/api'

const today = moment(new Date()).format('YYYY-MM-DD')

export const MapContext = createContext({})

const MapProvider = ({ children }) => {
  const map = useMap()
  const apiIsLoaded = useApiIsLoaded()
  const portPins = useSelector(selectPortPinData)
  const regionPins = useSelector(selectRegionPinData)
  const sailings = useSelector(selectSailings)
  const selectedDestination = useSelector(selectDestination)
  const selectedOrigin = useSelector(selectOrigin)
  const selectedShip = useSelector(selectShip)
  const selectedShipDetails = useSelector(selectShipDetails)
  const selectedRoute = useSelector(selectRoute)
  const selectedFilter = useSelector(selectFilter)
  const routes = useSelector(selectRoutes)
  const skipShipSelection = useSelector(selectSkipShipSelection)

  // map specific states
  const [allData, setAllData] = useState([])
  const [regionData, setRegionData] = useState([])
  const [directionService, setDirectionService] = useState(null)
  const [directionsRenderer, setDirectionsRenderer] = useState(null)
  const [travelMode, setTravelMode] = useState('Car')
  const [selectedDate, setSelectedDate] = useState(today)
  const [selectedTime, setSelectedTime] = useState(getNearestQuarterHour())
  const [zoomLevel, setZoomLevel] = useState(3)

  const portsToPersist = ['Miami, Florida']
  const regionsToPersist = ['Caribbean']

  const dispatch = useDispatch()

  // region/port information to grab coming from Ship Locator
  const { state } = useLocation()

  const routesLibrary = useMapsLibrary('routes')

  const travelModeMap = map
    ? {
        Car: google.maps.TravelMode.DRIVING, //eslint-disable-line
        Bicycle: google.maps.TravelMode.BICYCLING, //eslint-disable-line
        Walking: google.maps.TravelMode.WALKING, //eslint-disable-line
        Transit: google.maps.TravelMode.TRANSIT, //eslint-disable-line
      }
    : null

  const zoomIn = () => {
    setZoomLevel(zoomLevel + 1)
    map.setZoom(zoomLevel + 1)
  }

  const zoomOut = () => {
    if (zoomLevel > 2) {
      setZoomLevel(zoomLevel - 1)
      map.setZoom(zoomLevel - 1)
    }
  }

  const moveCameraBack = () => {
    map.moveCamera({
      zoom: 3,
      center: { lat: 22.54992, lng: 0 },
    })
  }

  const resetMap = () => {
    dispatch(resetInitialState())
    directionsRenderer.setDirections(null)
  }

  const handleBackClick = (handlers) => {
    if (selectedRoute) {
      dispatch(setSelectedRoute(null))
    } else if (selectedOrigin) {
      handlers.clearOrigin()
      setSelectedDate(today)
      setSelectedTime(getNearestQuarterHour())
      dispatch(setRoutes([]))
      dispatch(setSelectedOrigin(null))
    } else if (selectedDestination) {
      resetMap()
      if (selectedShip) {
        dispatch(setSelectedShip(null))
      } else if (skipShipSelection) {
        dispatch(setSkipShipSelection(false))
      }
    }
  }

  const clearDestination = () => {
    resetMap()
    moveCameraBack()
    setSelectedDate(today)
    dispatch(resetInitialState())
  }
  const clearOrigin = () => {
    resetMap()
    setSelectedDate(today)
    setSelectedTime(getNearestQuarterHour())
    dispatch(setSelectedOrigin(null))
    dispatch(setSelectedRoute(null))
    dispatch(setRoutes(null))
  }

  const onDestinationSelect = (port) => {
    if (map) {
      map.moveCamera({
        zoom: 12,
        center: {
          lat: port.lat,
          lng: port.lng,
        },
      })
      dispatch(setSelectedFilter('homeports'))
      dispatch(setSelectedDestination(port))
    }
  }

  const zoomIntoRegion = (region) => {
    if (map) {
      dispatch(setSelectedFilter('homeports'))
      map.moveCamera({
        zoom: 5,
        center: {
          lat: region.lat,
          lng: region.lng,
        },
      })
    }
  }

  const formatAEMAddress = (portFromAEM) => {
    return `${portFromAEM.addressLine1}${
      portFromAEM.addressLine2 ? ' ' + portFromAEM.addressLine2 : ''
    }${portFromAEM.city ? ', ' + portFromAEM.city : ''}${
      portFromAEM.stateprovince ? ', ' + portFromAEM.stateprovince : ''
    }, ${portFromAEM.country}`
  }

  // Effect to combine all port data
  useEffect(() => {
    setRegionData(
      regionPins.map((region) => ({
        ...region,
        rank: regionsToPersist.includes(region.label) ? 2 : 1,
      }))
    )
    const fetchPortsAndShips = async () => {
      try {
        const apiPorts = await getPortsFromAEM()
        // Create a map of ports from API for easy lookup by portCode
        const portsFromAPI = apiPorts.data.items.reduce((acc, portFromAEM) => {
          acc[portFromAEM.portCode] = portFromAEM
          return acc
        }, {})

        // Process ports and ships in a single pass
        const { ports, ships } = portPins.reduce(
          (acc, port) => {
            const portFromAEM = portsFromAPI[port.shortCode]
            if (portFromAEM) {
              const portData = {
                shortCode: port.shortCode,
                type: port.type,
                brands: port.brands,
                label: port.label,
                dates: port.dates,
                lat: parseFloat(portFromAEM.latitude),
                lng: parseFloat(portFromAEM.longitude),
                ships: port.ships,
                address: formatAEMAddress(portFromAEM),
                rank: portsToPersist.includes(port.label) ? 2 : 1,
              }
              acc.ports.push(portData)

              // If port is a homeport, add ships to the ships array
              if (port.type === 'homeport') {
                const homeportShips = port.ships.map((ship) => ({
                  ...ship,
                  id: `${port.shortCode}_${ship.shipCode}`,
                  type: 'ship',
                  label: ship.name,
                  port,
                  dates: port.dates,
                  lat: port.lat,
                  lng: port.lng,
                }))
                acc.ships.push(...homeportShips)
              }
            }
            return acc
          },
          { ports: [], ships: [] } // Initial accumulator with empty arrays for ports and ships
        )

        setAllData([...ports, ...ships])
      } catch (error) {
        // console.error('Error fetching port data:', error)
      }
    }

    fetchPortsAndShips()
  }, [selectedFilter, regionPins, portPins])

  // Effect to determine if the user selected a port/region/ship
  // from ShipLocator, if so, which one
  useEffect(() => {
    if (state) {
      let portSelectedFromShipLocator = null

      switch (state.type) {
        case 'region': {
          const region = regionPins.find(
            (area) => area.shortCode === state.region.shortCode
          )

          if (region) zoomIntoRegion(region)

          break
        }

        case 'ship': {
          const ship = allData
            .filter((data) => data.type === 'ship')
            .find((item) => item.name === state.ship.name)

          if (ship) {
            portSelectedFromShipLocator = portPins.find(
              (port) => port.label === ship.port.label
            )

            dispatch(setSelectedShip(ship))
          }

          break
        }
        case 'regionOrShip': {
          portSelectedFromShipLocator = portPins.find(
            (port) => port.label === state.selectedPort
          )

          if (state.shipName) {
            const shipFromShipLocator = allData
              .filter((portOrShip) => portOrShip.type === 'ship')
              .find((item) => item.name === state.shipName)

            if (shipFromShipLocator) {
              dispatch(setSelectedShip(shipFromShipLocator))
            }
          }

          break
        }
        default: {
          portSelectedFromShipLocator = portPins.find(
            (port) => port.shortCode === state.selectedPort
          )

          break
        }
      }

      if (portSelectedFromShipLocator)
        onDestinationSelect(portSelectedFromShipLocator)
    }
  }, [state, map, allData])

  // Basic directions init effect
  useEffect(() => {
    if (!map || !routesLibrary) {
      return
    }
    setDirectionService(new routesLibrary.DirectionsService())

    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }))
  }, [map, routesLibrary])

  // Effect to fetch directions for the given destination,
  // provided origin, travelMode, date and time
  useEffect(() => {
    if (!directionService || !directionsRenderer || !map) {
      return
    }

    dispatch(setRoutes([]))
    dispatch(setSelectedRoute(null))

    if (!selectedOrigin || !selectedDestination) {
      directionsRenderer.setMap(null)
      return
    }
    directionsRenderer.setMap(map)

    const configObject = {
      origin: selectedOrigin.address,
      destination: {
        lat: selectedDestination.lat,
        lng: selectedDestination.lng,
      },
      travelMode: travelModeMap[travelMode],
      provideRouteAlternatives: true,
    }

    if (travelMode === 'Transit') {
      configObject.transitOptions = {
        arrivalTime: moment(`${selectedDate} ${selectedTime}`).toDate(),
        modes: ['BUS', 'RAIL', 'SUBWAY', 'TRAIN', 'TRAM'],
        routingPreference: 'FEWER_TRANSFERS',
      }
    }

    directionService.route(configObject).then((res) => {
      directionsRenderer.setDirections(res)
      dispatch(setRoutes(res.routes))
    })
  }, [
    directionService,
    directionsRenderer,
    selectedOrigin,
    selectedDestination,
    map,
    selectedDate,
    selectedTime,
    travelMode,
  ])

  return apiIsLoaded ? (
    <MapContext.Provider
      value={{
        map,
        allData,
        portPins,
        regionData,
        selectedDestination,
        selectedOrigin,
        selectedShip,
        selectedShipDetails,
        selectedRoute,
        onDestinationSelect,
        onOriginSelect: (selection) => dispatch(setSelectedOrigin(selection)),
        clearDestination,
        clearOrigin,
        onShipSelect: (ship) => dispatch(setSelectedShip(ship)),
        onShipDetailsSelect: (ship) => dispatch(setSelectedShipDetails(ship)),
        onSelectRoute: (route) => dispatch(setSelectedRoute(route)),
        directionsRenderer,
        routes,
        selectedFilter,
        setSelectedFilter: (filter) => dispatch(setSelectedFilter(filter)),
        skipShipSelection,
        skipAndGetDirectionsToPort: () => dispatch(setSkipShipSelection(true)),
        shouldShowOriginInput: () =>
          selectedDestination && (selectedShip || skipShipSelection),
        handleBackClick,
        zoomIntoRegion,
        selectedDate,
        setSelectedDate,
        selectedTime,
        setSelectedTime,
        travelMode,
        setTravelMode,
        zoomIn,
        zoomOut,
        sailings,
      }}
    >
      {children}
    </MapContext.Provider>
  ) : (
    <Loading />
  )
}

export const useMapContext = () => useContext(MapContext)

MapProvider.propTypes = {
  children: node.isRequired,
}

export default MapProvider
