import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { func } from 'prop-types'
import BrandAndShipClassFilter from '../../components/BrandAndShipClassFilter/BrandAndShipClassFilter'
import MapAndScheduleMenuItem from '../../components/MapAndScheduleMenuItem/MapAndScheduleMenuItem'
import shipsIcon from '../../assets/icons/menu-ships-icon.svg'

import { selectBrandsFilterOptions } from '../../redux/brands/brandsSelectors'
import {
  selectShipsListData,
  selectShipsBrandFilter,
  selectShipsClassFilter,
  selectShipClassFilterOptions,
} from '../../redux/ships/shipsSelectors'
import {
  setShipsBrandFilter,
  setShipsClassFilter,
} from '../../redux/ships/shipsActions'

import './ShipsContainer.scss'
import { setViewOrigin } from '../../redux/view/viewActions'
import { useUI } from '../../context/modalContext'

const ShipsContainer = ({ swapView }) => {
  const dispatch = useDispatch()
  const { navigateTo } = useUI()
  const mockHandleClickViewMap = () => {}

  const brands = useSelector(selectBrandsFilterOptions)
  const shipClasses = useSelector(selectShipClassFilterOptions)
  const ships = useSelector(selectShipsListData)
  const brandFilter = useSelector(selectShipsBrandFilter)
  const classFilter = useSelector(selectShipsClassFilter)

  const hideViewOnMap = true

  const handleHomePort = (ship) => {
    navigateTo('/directions-to-port', {
      ship,
      type: 'ship',
    })
  }

  const onMenuItemClick = (menuItem, shipName) => {
    window.utag.link({
      event_name: 'click',
      page_url: window.location.href,
      category: 'ship search',
      interaction_type: 'ship click',
      nav_link_text: menuItem,
      ship: shipName, // populate with the ship name.
      brand: brandFilter, // populate with the brand of the ship.
      ship_class: classFilter, // populate with the ship class.
    })
  }

  const handleScheduleClick = (ship) => {
    dispatch(setViewOrigin('Ships'))
    swapView('ship', ship.shortCode)
  }
  return (
    <div className="ships-container">
      <header>
        <div className="title-row">
          <img src={shipsIcon} alt="" />
          <div className="title">Ships</div>
        </div>

        <BrandAndShipClassFilter
          brands={brands}
          shipClasses={shipClasses}
          selectedBrand={brandFilter}
          selectedShipClass={classFilter}
          onNewBrand={(brand) => {
            window.utag.link({
              event_name: 'ship_search',
              brand,
            })
            dispatch(setShipsBrandFilter(brand))
          }}
          onNewShipClass={(shipClass) => {
            window.utag.link({
              event_name: 'ship_search',
              ship_class: shipClass,
            })
            dispatch(setShipsClassFilter(shipClass))
          }}
        />
      </header>

      <main>
        {ships.map((ship) => {
          return (
            <MapAndScheduleMenuItem
              key={ship.shortCode}
              name={ship.name}
              onClickSchedule={() => {
                onMenuItemClick('Schedule', ship.name)
                handleScheduleClick(ship)
              }}
              onClickViewMap={mockHandleClickViewMap}
              hideViewOnMap={hideViewOnMap}
              homePort="Home Port"
              onClickHomePort={() => {
                onMenuItemClick('Home Port', ship.name)
                handleHomePort(ship)
              }}
            />
          )
        })}
      </main>
    </div>
  )
}

ShipsContainer.propTypes = {
  swapView: func.isRequired,
}

export default ShipsContainer
