import React, { useEffect, useState } from 'react'
import './Sidebar.scss'

import usePlacesAutocomplete from 'use-places-autocomplete'
import { Link } from 'react-router-dom'
import { useSSO } from '../../../hooks/useSSO'
import BackArrow from '../../../assets/icons/back.svg'
import { ReactComponent as Logo } from '../../../assets/icons/directions-to-port-logo.svg'
import DestinationList from '../DestinationList/DestinationList'
import PlacesAutocomplete from '../PlacesAutocomplete/PlacesAutocomplete'
import RoutesList from '../RoutesList/RoutesList'
import DestinationAndOriginSearch from '../DestinationAndOriginSearch/DestinationAndOriginSearch'
import ShipSelect from '../ShipSelect/ShipSelect'
import { useMapContext } from '../MapProvider'
import MailSend from '../../../assets/icons/mail-send.svg'
import DocumentIcon from '../../../assets/icons/document.svg'
import { useUI } from '../../../context/modalContext'

const Sidebar = () => {
  const [searchedDestination, setSearchedDestination] = useState('')
  const [resultsToShow, setResultsToShow] = useState([])
  const ssoParam = useSSO()
  const {
    map,
    allData,
    selectedDestination,
    clearDestination,
    clearOrigin,
    shouldShowOriginInput,
    handleBackClick,
    selectedRoute,
    selectedOrigin,
    selectedShipDetails,
    onShipDetailsSelect,
  } = useMapContext()
  const { handleClickAnalyticsData } = useUI()

  const link = selectedRoute
    ? `https://www.google.com/maps/dir/?api=1&origin=${selectedRoute.legs[0].start_address}&destination=${selectedRoute.legs[0].end_address}&travelmode=driving`
    : ''

  const {
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      locationBias: map.getBounds(),
    },
  })

  const handleFilter = (type) => {
    const results = allData.filter((destination) =>
      destination.label
        .toLowerCase()
        .includes(searchedDestination.toLowerCase())
    )

    if (type === 'all') {
      setResultsToShow(results)
    } else {
      setResultsToShow(results.filter((result) => result.type === type))
    }
  }

  const handleDetails = (ship) => {
    onShipDetailsSelect(ship)
  }

  useEffect(() => {
    if (searchedDestination === '') {
      setResultsToShow([])
    } else {
      setResultsToShow(
        allData.filter((destination) =>
          destination.label
            .toLowerCase()
            .includes(searchedDestination.toLowerCase())
        )
      )
    }
  }, [searchedDestination])

  return (
    <div
      style={{ height: searchedDestination === '' ? 'fit-content' : '90%' }}
      id="dtp-side-bar"
    >
      <header>
        <Logo className="logo" />
      </header>
      <main>
        <div className="sidebar-content-container">
          {shouldShowOriginInput() && (
            <div
              onClick={() => {
                handleClickAnalyticsData('Back')
                handleBackClick({
                  clearOrigin: () => setValue(''),
                })
              }}
              className="back-arrow"
            >
              <img src={BackArrow} alt="back-arrow" />
              <span className="back-arrow-text">Back</span>
            </div>
          )}
          <DestinationAndOriginSearch
            value={value}
            searchedDestination={searchedDestination}
            onDestinationChange={(e) => setSearchedDestination(e.target.value)}
            onOriginChange={(e) => setValue(e.target.value)}
            clearDestination={() => {
              setSearchedDestination('')
              clearDestination()
            }}
            clearOrigin={() => {
              setValue('')
              clearOrigin()
            }}
          />
          <DestinationList
            searchedDestination={searchedDestination}
            resultsToShow={resultsToShow}
            setSearchedDestination={setSearchedDestination}
            onFilter={handleFilter}
          />
          {selectedDestination && <ShipSelect onChange={handleDetails} />}
          <PlacesAutocomplete
            value={value}
            status={status}
            data={data}
            setValue={setValue}
            clearSuggestions={clearSuggestions}
          />
          <RoutesList />
        </div>
      </main>
      <footer>
        {selectedRoute && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <a
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              target="_blank"
              href={`https://www.google.com/maps/dir/?api=1&origin=${selectedRoute.legs[0].start_address}&destination=${selectedRoute.legs[0].end_address}&travelmode=driving`}
              rel="noreferrer"
              onClick={() => {
                window.utag.link({
                  event_name: 'click',
                  page_url: window.location.href,
                  category: 'sidebar',
                  interaction_type: 'Directions to Port',
                  nav_link_text: 'View on Google Maps', // set this as link text.
                })
              }}
            >
              View on Google Maps
              <img src={DocumentIcon} alt="document-icon" />
            </a>
            <div
              style={{
                position: 'sticky',
                cursor: 'pointer',
                backgroundColor: '#0c214d',
                borderRadius: 35,
                padding: 16,
                paddingLeft: 24,
                paddingRight: 24,
              }}
              onClick={() =>
                handleClickAnalyticsData(
                  'Send Route',
                  '/directions-to-port/email'
                )
              }
            >
              <Link
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
                to={{
                  pathname: '/directions-to-port/email',
                  search: ssoParam,
                  state: {
                    mapsLink: link,
                    origin: selectedOrigin.address,
                    destination: selectedDestination.label,
                    details: selectedShipDetails,
                  },
                }}
              >
                Send Route
                <img
                  style={{
                    marginLeft: 4,
                  }}
                  src={MailSend}
                  alt="mail-send"
                />
              </Link>
            </div>
          </div>
        )}
        <div className="copyright">
          &copy; {new Date().getFullYear()} Royal Caribbean International
        </div>
      </footer>
    </div>
  )
}

export default Sidebar
