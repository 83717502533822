import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { func } from 'prop-types'

import './LandingModal.scss'

import landingImage from '../../assets/images/landing-modal-bg.png'
import closeIcon from '../../assets/icons/close-btn.svg'
import launchIcon from '../../assets/icons/launch-map.svg'
import { ReactComponent as ShipLocatorLogo } from '../../assets/icons/ship-locator-logo.svg'

import MapSearchResult from '../MapSearch/MapSearchResult'
import selectSearchableData from '../../redux/selectors/selectSearchableData'

import { useUI } from '../../context/modalContext'

const LandingModal = ({ handleSelection }) => {
  const { closeModal } = useUI()
  const inputRef = useRef(null)
  const searchableData = useSelector(selectSearchableData)
  const [searchResults, setSearchResults] = useState([])

  const autoSearch = (e) => {
    window.utag.link({
      event_name: 'landing_modal_search',
      page_url: window.location.href,
      search_bar_name: 'Landing modal search bar',
      search_term: e.target.value,
    })
    if (e.target.value.length <= 1) {
      return setSearchResults([])
    }
    const searchTerm = e.target.value.toLowerCase()
    const condition = (data) => {
      if (
        data.type === 'port' &&
        (data?.countries?.some((country) =>
          country.toLowerCase().startsWith(searchTerm)
        ) ||
          data?.states?.some((state) =>
            state.toLowerCase().startsWith(searchTerm)
          ))
      ) {
        return true
      }

      return data.name.toLowerCase().startsWith(searchTerm)
    }
    const filteredResult = searchableData.filter(condition)
    setSearchResults(filteredResult)
    return filteredResult
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  return (
    <div className="landing-modal">
      <div className="center-content">
        <img src={landingImage} alt="background" className="background-image" />
        <div className="assets-content">
          <div className="top-row">
            <button type="button" className="close-button" onClick={closeModal}>
              <img src={closeIcon} alt="close" />
            </button>
          </div>
          <ShipLocatorLogo className="logo" />
          <div className="welcome-row">
            <h1 className="welcome-text">Welcome to Ship Locator</h1>
          </div>
          <div className="intro-row">
            <p>
              With our new tool you can quickly locate where Royal Caribbean
              International and Celebrity Cruises ships are deployed across the
              globe! You’ll also be able to keep up with all the latest
              deployment information by ship, region, and even for individual
              ports of call, as well as view and download the most current
              announcements.
            </p>
          </div>
          <div className="search-input-row">
            <input
              type="text"
              placeholder="Search by Ship, Region Or Port"
              className="with-search-icon"
              onChange={autoSearch}
              ref={inputRef}
            />
            {!!searchResults.length && (
              <div className="component-map-search-results open-upwards">
                {searchResults.map((searchItem, idx) => (
                  <MapSearchResult
                    key={idx}
                    searchResult={searchItem}
                    onSelect={(type, shortCode) => {
                      window.utag.link({
                        event_name: 'ship_locator_search',
                        page_url: window.location.href,
                        search_bar_name: 'search',
                        search_term: inputRef.current.value,
                      })
                      handleSelection(type, shortCode)
                    }}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="footer-row">
            <button type="button" className="btn launch" onClick={closeModal}>
              <img src={launchIcon} alt="launch" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

LandingModal.propTypes = {
  handleSelection: func.isRequired,
}

export default LandingModal
