import React from 'react'
import propTypes from 'prop-types'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import rciLogo from '../../assets/images/rci-logo.png'
import ccLogo from '../../assets/images/cc-logo.png'
import ccLogoX from '../../assets/icons/cc-logo-x.png'
import rcLogo from '../../assets/icons/deployments-logo.svg'
import rcHeader from '../../assets/images/royal-caribbean-header.png'
import ccHeader from '../../assets/images/celebrity-cruises-header.png'
import Close from '../../assets/icons/close-circle-x.png'

import './EmailPreview.scss'

const EmailPreview = ({ email, onClick, ...rest }) => {
  const handleInputs = (target, defaultText) =>
    target && target !== '' ? target : defaultText

  const { state } = useLocation()
  const mapsLink = state?.mapsLink || ''
  const origin = state?.origin || ''
  const destination = state?.destination || ''

  return (
    <div
      {...rest}
      className={`email-preview ${rest.className ? rest.className : ''}`}
    >
      <div
        className="email-preview-edge"
        onClick={onClick}
        alt="Email Preview Toggle"
      >
        <img
          className="email-preview-edge-btn"
          src={Close}
          alt="Close Email Success"
        />
      </div>
      <img
        className="email-preview-image"
        src={email.brand === 'C' ? ccHeader : rcHeader}
        alt="email preview"
      />
      <div className="email-section">
        <div className="email-message">
          <p>Dear {handleInputs(email.clientName, '[Client name]')},</p>
          <p>
            As you plan your vacation, I want to make your journey as smooth as
            possible. Included in this email is a link that provides directions
            to the port, so you have one less thing to worry about!
          </p>
          <p>
            If you encounter any issues with the address, feel free to check the
            mobile app or contact me directly.
          </p>

          <div
            className={`email-destination ${
              email.brand === 'C' ? 'cc-location-bg' : 'rc-location-bg'
            }`}
          >
            {email.brand === 'R' && <img src={rcLogo} alt="Royal Caribbean" />}
            {email.brand === 'C' && (
              <img
                className="celebrity-logo"
                src={ccLogoX}
                alt="Celebrity Cruises"
              />
            )}

            <div
              className={`email-location-start ${
                email.brand === 'C' ? 'cc-border' : 'rc-border'
              }`}
            >
              <p>Starting Location</p>
              <p className="email-location">{`${handleInputs(
                origin,
                '[starting location]'
              )}`}</p>
            </div>
            <div className="email-location-finish">
              <p>Embarkation Location</p>
              <p className="email-location">{`${handleInputs(
                destination,
                '["port" OR "terminal"]'
              )}`}</p>
            </div>
          </div>

          <div className="email-section">
            <div
              className={
                email.brand === 'C'
                  ? 'email-view-directions-cc'
                  : 'email-view-directions-rc'
              }
            >
              <a
                className="email-link"
                href={mapsLink}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  window.utag.link({
                    event_name: 'click',
                    page_url: window.location.href,
                    category: 'email',
                    interaction_type: 'Directions to Port',
                    nav_link_text: 'View Directions On Google Maps',
                  })
                }}
              >
                View Directions On Google Maps
              </a>
            </div>
          </div>

          <p>Bon voyage, and I hope you enjoy your adventure!</p>
          <p>
            Best Regards,
            <br />
            {handleInputs(email.agentName, '[Agent]')}
            <br />
            {handleInputs(email.agencyName, '[Agency]')}
            <br />
            {handleInputs(email.agencyEmail, '[Agent Email Address]')}
            <br />
          </p>
        </div>
      </div>

      <div className="email-footer">
        <div className="email-view-disclaimer">
          This feature is intended for informational purposes only. We do not
          guarantee the completeness, reliability, or accuracy of the
          information. Please note that terminal assignments may change without
          notice, and it is the user&apos;s responsibility to confirm the
          correct terminal prior to arrival. We disclaim any liability for any
          loss, inconvenience, or damages arising from reliance on the
          directions provided. Always follow local traffic laws and regulations.
        </div>

        <div className="email-footer-logos">
          <img
            className="email-rci-logo"
            src={email.brand === 'C' ? ccLogo : rciLogo}
            alt="Royal Caribbean Logo"
          />
        </div>
        <div className="email-copyright">
          {email.brand === 'C' && (
            <span>
              &copy;2024 Celebrity Cruises Inc. Ship&apos;s registry: Malta and
              Ecuador.
            </span>
          )}
          {email.brand === 'R' && (
            <span>
              &copy;2024 Royal Caribbean Cruises Ltd. Ship&apos;s registry: The
              Bahamas. All Right Reserved. Ecuador.
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

EmailPreview.defaultProps = {
  email: {},
  onClick: () => {},
}

EmailPreview.propTypes = {
  email: propTypes.shape({
    recipients: propTypes.string,
    copy: propTypes.bool,
    clientName: propTypes.string,
    agentName: propTypes.string,
    agencyName: propTypes.string,
    agencyEmail: propTypes.string,
    additionalComments: propTypes.string,
    brand: propTypes.string,
  }),
  onClick: propTypes.func,
}

export default EmailPreview
