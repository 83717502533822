import { createSelector } from 'reselect'

import { PinTypeName } from '../../configuration/constants'
import {
  selectSidebarViewResource,
  selectSidebarPinType,
} from '../view/viewSelectors'
import { selectSailings, selectPackages } from '../sailings/sailingsSelectors'
import { selectShipsIndexed } from '../ships/shipsSelectors'
import { selectRegionsIndexed } from '../regions/regionsSelectors'
import { selectPortsIndexed } from '../ports/portsSelectors'
import { selectPortDeploymentsFilter } from '../filters/filtersSelectors'

const selectPortViewDeployments = createSelector(
  selectSidebarPinType,
  selectSidebarViewResource,
  selectSailings,
  selectPackages,
  selectRegionsIndexed,
  selectShipsIndexed,
  selectPortsIndexed,
  selectPortDeploymentsFilter,
  (pinType, port, sailings, packages, regions, ships, ports, filter) => {
    const resultPackages = {}

    const validateYearMonth = (yearMonth) => {
      const compareString = `${filter.year}${
        filter.month ? '-' + filter.month : ''
      }`
      return yearMonth.startsWith(compareString)
    }
    const validatePortOfCall = (packageData) => {
      if (filter.portOfCall?.length) {
        return !!filter.portOfCall.find((portOfCall) =>
          packageData.ports.includes(portOfCall)
        )
      }
      return true
    }

    const homePortFilter = (packageData, portCode) =>
      packageData.departurePort === portCode
    const callPortFilter = (packageData, portCode) =>
      packageData.ports.includes(portCode)
    const portTypeFilter =
      pinType === PinTypeName.HOME_PORT ? homePortFilter : callPortFilter

    // Get valid packages for region by port code
    Object.entries(packages || {}).forEach(([packageId, packageData]) => {
      if (
        portTypeFilter(packageData, port) &&
        validatePortOfCall(packageData)
      ) {
        const portsOfPackage = [packageData.departurePort, ...packageData.ports]
        resultPackages[packageId] = {
          name: packageData?.itinerary?.description || packageData.description,
          shortCode: packageId,
          region: regions[packageData.destinationCode]?.name,
          sailDates: [],
          brand: packageData.brand,
          departurePortCode: packageData.departurePort,
          ports: portsOfPackage.map((portCode) => {
            if (!ports[portCode]) {
              return `MISSING ${portCode}`
            }
            return ports[portCode].name
          }),
          departurePort: ports[packageData.departurePort]?.name || '',
          ship: ships[packageData.shipCode].name,
          shipCode: packageData.shipCode, // NOTE(ebarrett): Might be necessary for links
        }
      }
    })

    // Now iterate through sailings to get package dates
    const yearMonths = Object.keys(sailings)
    yearMonths.forEach((yearMonth) => {
      if (!validateYearMonth) {
        return
      }
      Object.values(sailings[yearMonth]).forEach((shipPackageList) => {
        shipPackageList.forEach(({ sailDate, packageId }) => {
          if (resultPackages[packageId] && validateYearMonth(sailDate)) {
            resultPackages[packageId].sailDates.push(sailDate)
          }
        })
      })
    })

    let tableRows = Object.values(resultPackages)
    if (filter.ship.length) {
      tableRows = tableRows.filter((row) => filter.ship.includes(row.shipCode))
    }
    tableRows = tableRows.filter((row) => !!row.sailDates.length)

    return tableRows // TODO(ebarrett): Sort by first sail date?
  }
)

export default selectPortViewDeployments
