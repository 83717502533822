import React from 'react'
import propTypes from 'prop-types'

import { RoundedButton } from '../../components/RoundedButton/RoundedButton'

import './ViewToggle.scss'

const ViewToggle = ({ status, onClick, ...rest }) => {
  return (
    <div
      {...rest}
      className={`toggle-view ${rest.className ? rest.className : ''}`}
    >
      <RoundedButton
        className="toggle-btn"
        label="TOGGLE PREVIEW"
        onClick={onClick}
      />
    </div>
  )
}

ViewToggle.defaultProps = {
  onClick: () => {},
  status: false,
}

ViewToggle.propTypes = {
  onClick: propTypes.func,
  status: propTypes.bool,
}

export default ViewToggle
