import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import moment from 'moment'
import Calendar from 'react-calendar'
import { useMapContext } from '../MapProvider'
import SelectWithLabel from '../utils/components/SelectWithLabel'

import 'react-calendar/dist/Calendar.css'
import './ShipSelect.scss'
import { useUI } from '../../../context/modalContext'

const ShipSelect = ({ onChange }) => {
  const {
    selectedDestination,
    selectedShip,
    onShipSelect,
    skipShipSelection,
    skipAndGetDirectionsToPort,
    sailings,
  } = useMapContext()
  const { handleClickAnalyticsData } = useUI()

  const [dates, setDates] = useState([])

  const [ship, setShip] = useState(
    selectedDestination && selectedDestination.type === 'ship'
      ? selectedDestination.shipCode
      : ''
  )

  useEffect(() => {
    let newDates = []

    Object.entries(sailings).forEach(([month, ships]) => {
      const isShipPresent = Object.keys(sailings[month]).includes(ship)
      if (isShipPresent) {
        newDates = [...newDates, ...ships[ship].map((s) => s.sailDate)]
      }

      setDates(newDates)
    })
  }, [ship])

  const [selectedYear, setSelectedYear] = useState(
    moment(dates[0]).year().toString()
  )
  const [activeDate, setActiveDate] = useState(moment(dates[0]).toDate())

  const ships = ['homeport', 'region', 'portofcall'].includes(
    selectedDestination.type
  )
    ? selectedDestination.ships
    : selectedDestination.port.ships

  const selectableYears = () => {
    const years = []
    const minYear = moment(dates[0]).year()

    for (let i = 0; i < 4; i++) {
      years.push(
        moment()
          .year(minYear + i)
          .format('YYYY')
      ) // Push the year to the array
    }

    return years
  }

  const handleYearChange = (year) => {
    const date = moment(year, 'YYYY').toDate()
    setActiveDate(date)
    setSelectedYear(year)
  }

  const handleShipSelect = (e) => {
    e.preventDefault()
    const shipDetails = ships.find((item) => item.shipCode === e.target.value)
    setShip(e.target.value)
    onChange(shipDetails)
  }

  return (
    !(selectedShip || skipShipSelection) && (
      <div className="ship-select-container">
        <div>
          Select ship and sail date to get directions to the correct terminal:
        </div>
        <div className="dropdown-container">
          <SelectWithLabel
            placeholder="Select Ship"
            label="Ship:"
            values={ships.map((s) => ({
              key: s.shipCode,
              label: s.name,
            }))}
            selectedValue={ship}
            onChange={handleShipSelect}
          />
        </div>
        {ship && (
          <div className="calendar-container">
            <div
              style={{
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              Sailing on:
            </div>
            <div>
              <div className="year-container">
                {selectableYears().map((year) => (
                  <div
                    key={year}
                    onClick={() => handleYearChange(year)}
                    className={`year-item ${
                      selectedYear === year ? 'active' : ''
                    }`}
                  >
                    {year}
                  </div>
                ))}
              </div>
              <Calendar
                className="calendar-container"
                minDetail="year"
                next2Label=""
                activeStartDate={activeDate}
                onActiveStartDateChange={({ activeStartDate }) => {
                  setSelectedYear(moment(activeStartDate).year().toString())
                  setActiveDate(activeStartDate)
                }}
                prev2Label=""
                tileClassName={({ activeStartDate, date, view }) =>
                  `date-tile ${
                    view === 'month' && date > activeStartDate
                      ? 'future-dates'
                      : ''
                  } ${
                    dates.includes(moment(date).format('yyyy-MM-DD'))
                      ? 'selectable-date'
                      : ''
                  }`
                }
                minDate={moment(dates[0]).toDate()}
                onChange={() => {
                  handleClickAnalyticsData(ship)
                  onShipSelect(ship)
                }}
                tileDisabled={({ date }) =>
                  !dates.includes(moment(date).format('yyyy-MM-DD'))
                }
              />
            </div>
          </div>
        )}
        <div onClick={skipAndGetDirectionsToPort} className="skip-text">
          Skip and get directions to port
        </div>
      </div>
    )
  )
}

ShipSelect.propTypes = {
  onChange: propTypes.func,
}

ShipSelect.defaultProps = {
  onChange: () => null,
}

export default ShipSelect
